import React, { useState, useEffect } from 'react';
import LoadScripts from '../Components/LoadScripts';
import { Link } from 'react-router-dom';
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';
import StoreApi from '../Controllers/StoreApi';

export default function Checkout1() {
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [expiredCoupon, setExpiredCoupon] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [amountSet, setAmountSet] = useState(true);
  const [validCoupon, setValidCoupon] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [shipping, setShipping] = useState(0);
  const { handleOnChangeValue, addToCart, cartItems, handleDeleteItem } = ManageCart();
  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const [discount, setDiscount] = useState(0); // 159,00 in Rs.
// if(totalBilling <= 10000){

//   setShipping(1000)
// } 
// else {
//   setShipping(0)
// }

  const gst = 0;       // 59,00 in Rs.
  const { http } = StoreApi();
  const finalAmount = totalBilling - (discount) + (shipping) + (gst / 100);

  async function generateKey() {
    return crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
  }

  async function encryptCouponCode(couponCode, key) {
    const encoder = new TextEncoder();
    const data = encoder.encode(couponCode);
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const encrypted = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      data
    );
    return { iv, encrypted };
  }

  async function decryptCouponCode(encrypted, iv, key) {
    const decrypted = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encrypted
    );
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  }

  const checkCoupon = async (couponCode) => {
    setBtnDisable(false);
    setValidCoupon(false);
    setProcessing(false);
    setExpiredCoupon(false);
    setInvalidCoupon(false);
    setAmountSet(false);
    setDiscount(0);

    const key = await generateKey();

    const { iv, encrypted } = await encryptCouponCode(couponCode, key);

    http.post("/checkCoupon", { coupon: couponCode })
      .then(async response => {
        if (response.status === 200) {
          setCoupon(response.data);
          console.log('API response:', response.data);

          if (response.data.Status === 404) {
            setInvalidCoupon(true);
            setAmountSet(true);
            setProcessing(true);

          } else if (response.data.Coupon.NumberOfTime === 0) {
            setExpiredCoupon(true);
            setAmountSet(true);
            setProcessing(true);

          } else {
            setDiscount(response.data.Coupon.DiscountValue);
            setProcessing(true);
            setAmountSet(true);
            setValidCoupon(true);
            setBtnDisable(true);

            localStorage.setItem('coupon_iv', JSON.stringify(Array.from(iv)));
            localStorage.setItem('coupon_encrypted', JSON.stringify(Array.from(new Uint8Array(encrypted))));
            localStorage.setItem('coupon_key', JSON.stringify(await crypto.subtle.exportKey('jwk', key)));
            localStorage.setItem('coupon_code', couponCode); // Store the coupon code
          }
        } else {
          console.error("Fetching productDetail failed:", response.data.message);
        }
      })
      .catch(error => {
        console.error("Error checking coupon:", error);
      });
  };

  useEffect(() => {
    const storedIv = localStorage.getItem('coupon_iv');
    const storedEncrypted = localStorage.getItem('coupon_encrypted');
    const storedKey = localStorage.getItem('coupon_key');
    const storedCouponCode = localStorage.getItem('coupon_code'); // Retrieve the stored coupon code

    if (storedCouponCode) {
      setCouponCode(storedCouponCode); // Set the coupon code state
    }

    if (storedIv && storedEncrypted && storedKey) {
      const iv = new Uint8Array(JSON.parse(storedIv));
      const encrypted = new Uint8Array(JSON.parse(storedEncrypted));
      const key = crypto.subtle.importKey('jwk', JSON.parse(storedKey), { name: 'AES-GCM' }, true, ['decrypt']);

      key.then(async (importedKey) => {
        const decryptedCouponCode = await decryptCouponCode(encrypted.buffer, iv, importedKey);
        checkCoupon(decryptedCouponCode);
      });
    }
  }, []);

  async function resetCoupon() {
    localStorage.removeItem('coupon_iv');
    localStorage.removeItem('coupon_encrypted');
    localStorage.removeItem('coupon_key');
    localStorage.removeItem('coupon_code'); // Remove the stored coupon code
    setCoupon('');
    setCouponCode('');
    setValidCoupon(false);
    setInvalidCoupon(false);
    setExpiredCoupon(false);
    // setProcessing(false);
    // setAmountSet(false);
    setDiscount(0);
    setBtnDisable(false);
  }

  return (
    <>
      <Helmet>
        <title>Cart Items | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Cart Items | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <div>

        <section style={{ background: 'black' }}>
        </section>
        {/* ========================  Checkout ======================== */}
        <div className="step-wrapper">
          <div className="container">
            <div className="stepper">
              <ul className="row">
                <li className="col-md-3 active">
                  <span data-text="Cart items" />
                </li>
                <li className="col-md-3">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3">
                  <span data-text="Confirmation" />
                </li>
                <li className="col-md-3">
                  <span data-text="Receipt" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <section className="checkout">
          <div className="row" style={{ marginLeft: '6%', marginRight: '6%' }}>
            <div className="col-md-9 col-sm-12">
              <div>
                <header className="hidden">
                  <h3 className="h3 title">Checkout - Step 1</h3>
                </header>
                {/* ========================  Cart wrapper ======================== */}
                <div className="cart-wrapper">
                  {/*cart header */}
                  <div className="cart-block cart-block-header clearfix">
                    <div>
                      <span>Product</span>
                    </div>

                    <div>
                      <span>Name</span>
                    </div>


                    <div className='text-left'>
                      <span>Color</span>
                    </div>

                    <div className="text">
                      <span>Quantity</span>
                    </div>
                    <div className="text-right">
                      <span>Price</span>
                    </div>
                  </div>
                  {/*cart items*/}
                  <div className="clearfix">

                    {cartItems.length > 0 ? (
                      cartItems.map((item, index) => (
                        <div className="cart-block cart-block-item clearfix" key={index}>
                          <div className="image">
                            <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${item.attimage}`} alt style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
                          </div>
                          <div className="title">
                            <div className="h6">{item.Name} ({item.ArticleNo})</div>
                            <div>{item.description}</div>
                          </div>
                          <div className="title">
                            <div className="h6">{item.color}</div>
                          </div>
                          <div className="quantity">
                            <input
                              type="number"
                              value={item.quantity}
                              min="1"
                              onInput={(event) => {
                                const value = event.target.value;
                                // Remove decimals or negative numbers by replacing any non-digit characters
                                event.target.value = value.replace(/[^0-9]/g, '');
                              }}
                              className="form-control form-quantity"
                              onChange={(event) => handleOnChangeValue(event, index)}
                            />
                          </div>
                          <div className="price">
                            <span className="final h6">Rs. {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                            {/* <span className="discount">Rs. {item.originalPrice}</span> */}
                          </div>
                          <span
                            className="icon icon-cross icon-delete"
                            onClick={() => handleDeleteItem(index)}
                          />
                        </div>
                      ))
                    ) : (
                      <div className='cart-block cart-block-item clearfix'>
                        <h2 align="center">Empty Cart</h2>
                      </div>
                    )}
                  </div>
                  {/*cart prices */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>Discount</strong>
                      </div>
                      <div>
                        <span>Rs. {discount}</span>
                      </div>
                    </div>
                    <div className="cart-block cart-block-footer clearfix">
                  <div>
                    <strong>Shipping</strong>
                  </div>
                  <div>
                    <span>Rs. {shipping}</span>
                  </div>
                </div>
                {/* <div className="cart-block cart-block-footer clearfix">
                  <div>
                    <strong>GST</strong>
                  </div>
                  <div>
                    <span>Rs. {gst / 100}</span>
                  </div>
                </div> */}
                  </div>
                  {/*cart final price */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer cart-block-footer-price clearfix">
                      <div>
                        <span className="checkbox">
                          <input type="checkbox" id="couponCodeID" />
                          <label htmlFor="couponCodeID">Promo code</label>

                          {btnDisable ? (
                            <input type="text" className="form-control form-coupon" disabled value={couponCode} />
                          ) : (
                            <input type="text" className="form-control form-coupon" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} placeholder="Enter your coupon code" />
                          )}
                          <div style={{ display: 'flex', gap: '5px', marginTop: '15px' }}>
                            <button
                              style={{
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              className='form-control form-coupon btn btn-main'
                              onClick={() => checkCoupon(couponCode)}
                            >
                              Apply
                            </button>
                            {validCoupon && (
                              <button
                                style={{
                                  position: 'relative',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                className='form-control form-coupon btn btn-warning'
                                onClick={resetCoupon}
                              >
                                Reset
                              </button>
                            )}

                          </div>
                        </span>
                        {invalidCoupon && (
                          <span style={{ color: 'red' }}>Invalid Promo Code!</span>
                        )}
                        {expiredCoupon && (
                          <span style={{ color: 'red' }}>Expired Promo Code!</span>
                        )}
                        {validCoupon && (
                          <span style={{ color: 'green' }}>Promo Code Activated!</span>
                        )}
                      </div>
                      <div>
                        {amountSet ? (
                          <div className="h2 title">Rs.{finalAmount.toFixed(2)}</div>
                        ) : (
                          <div className="h2 title">Rs....</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========================  Cart navigation ======================== */}
                <div className="clearfix">
                  <div className="row">
                    <div className="col-xs-6">
                      <Link to="/shop" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Continue Shopping</Link>
                    </div>
                    <div className="col-xs-6 text-right">
                      {cartItems.length > 0 && (
                        processing ? (
                          <Link to="/checkout/information" className="btn btn-main"><span className="icon icon-cart" /> Proceed to delivery</Link>
                        ) : (
                          <button className="btn btn-main" disabled><span className="icon icon-cart" /> Proceed to delivery</button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div> {/*/container*/}
            </div>
            <div className="col-md-3 col-sm-12">
              <div>
                <header className="hidden">
                  <h3 className="h3 title">Checkout - Step 1</h3>
                </header>
                {/* ========================  Cart wrapper ======================== */}
                <div className="cart-wrapper">
                  {/*cart header */}
                  <div className="cart-block cart-block-header clearfix">
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <span>Customer Service</span>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className='cart-block cart-block-item clearfix' >
                     
                      <ul style={{ marginLeft: '4%', marginRight: '4%', marginTop: '10%' }}>
                        <li><span className="icon icon-phone-handset" /> +92-333-0348378</li>
                        <li><span className="icon icon-phone-handset" /> +92-55-111-936-936</li>
                        <li><span className="icon icon-envelope" /> shop@citizen.com.pk</li>
                        <hr />
                        <p>Sun-Thu and Sat: 09:00 AM – 05:00 PM <br /> Friday: Closed</p>
                      </ul>
                     
                      
                    </div>
                  </div>

                </div></div>
            </div>
          </div>

        </section>
      </div>
    </>
  );
}





