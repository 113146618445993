import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Category from "../FetchApi/Category";
import Products from "../FetchApi/Products";
import { IMG_BASE_URL } from "../config";

export default function Header() {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); 
  const [showSearchBar, setShowSearchBar] = useState(false); 
  const { loadCategory, categories } = Category();
  const { loadProducts, products } = Products();
  useEffect(() => {
    loadCategory();
    loadProducts();
  }, []); 

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/shop?search=${encodeURIComponent(searchTerm)}`);
    }
  };
  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };
  const hasCategories = Array.isArray(categories.categories);
  const hasProducts = Array.isArray(products.products);
  return (
    <>
      {hasProducts && hasCategories ? (
        <>
          <nav className="navbar-fixed">
            <div style={{ marginRight: "5%", marginLeft: "5%" }}>
              <div className="navigation navigation-top clearfix">
                <ul>
                <li>
                      <Link to="/cartItems">
                        <i
                          className="icon icon-cart"
                          style={{ fontSize: "2rem", color: "#ffbb00" }}
                        />
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={toggleSearchBar}
                        style={{ background: "none", border: "none" }}
                      >
                        <i
                          className="icon icon-magnifier"
                          style={{ fontSize: "2rem", color: "#ffbb00" }}
                        />
                      </button>
                    </li>
                </ul>
              </div>
              {" "}
              <div className="navigation navigation-main">
                {/* Setup your logo here*/}
                <Link to="/" className="logo">
                  <img src="/assets/images/logo1.png" alt="img" />
                </Link>
                {/* Mobile toggle menu */}
                <a href="#" className="open-menu">
                  <i className="icon icon-menu" />
                </a>
                {/* Convertible menu (mobile/desktop)*/}
                <div className="floating-menu">
                  {/* Mobile toggle menu trigger*/}
                  <div className="close-menu-wrapper">
                    <span className="close-menu">
                      <i className="icon icon-cross" />
                    </span>
                  </div>
                  <ul>
                    {/* <li>
                      <Link to="/">Home</Link>
                    </li> */}

                    {categories.categories.length > 0 && (
                      <>
                        {categories.categories.map((category, index) => (
                          <li>
                            <Link
                              to={`/shop?category=${encodeURIComponent(
                                category.Id
                              )}`}
                            >
                              {category.Name}{" "}
                              <span className="open-dropdown">
                                <i className="fa fa-angle-down" />
                              </span>
                            </Link>
                            <div className="navbar-dropdown">
                              <div className="navbar-box">
                                {/* box-1 (left-side)*/}
                                <div className="box-1" >
                                  <div className="image">
                                    <img
                                      src={`${IMG_BASE_URL}${category.Picture}`}
                                      alt={category.Name}
                                    />
                                  </div>
                                  <div className="box">
                                    <div className="h2">{category.Name}</div>
                                    <div className="clearfix">
                                      <p>{category.Description}</p>
                                      <Link
                                        className="btn btn-clean btn-big"
                                        to={`/shop?category=${encodeURIComponent(
                                          category.Id
                                        )}`}
                                      >
                                        Explore
                                      </Link>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/*/box-1*/}
                                {/* box-2 (right-side)*/}
                                <div
                                  className="box-2"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <div className="clearfix categories">
                                    <div className="row">
                                      {/*icon item*/}
                                      {/* <div className="col-sm-4 col-xs-6"> */}

                                      {/* <article style={{}}>
                                      <div className="figure-grid">
                                        <div className="image">
                                          <a href="#productid1" className="mfp-open">
                                            <img src="assets/images/product-1.png" alt width={360} />
                                          </a>
                                        </div>
                                        <div className="text" style={{ backgroundColor: 'black' }}>

                                          <h2 className='h2' >$ 1499,-</h2>

                                        </div>
                                      </div>
                                    </article>
 */}
                                      {hasProducts &&
                                      products.products.length > 0 ? (
                                        products.products
                                          .filter(
                                            (product) =>
                                              product.SubCategoryId ===
                                              category.Id
                                          )
                                          .slice(0, 6)
                                          .map((product, prodIndex) => (
                                            <div
                                              key={prodIndex}
                                              className="col-sm-4 col-xs-6"
                                            >
                                              <article>
                                                <div className="figure-grid">
                                                  <div className="image">
                                                    {product.Thumbnai ? (
                                                      <>
                                                        <Link
                                                          to={`/product/${product.Id}/${product.Slug}`}
                                                        >
                                                          {/* <center><img src={`${IMG_BASE_URL}${product.Thumbnai}`}  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center> */}

                                                          <center>
                                                            <img
                                                              src={`${IMG_BASE_URL}${product.Thumbnai}`}
                                                              style={{
                                                                width: "auto",
                                                                height: "auto",
                                                                maxWidth:
                                                                  "100%",
                                                                maxHeight:
                                                                  "200px",
                                                                transition:
                                                                  "transform 0.3s ease",
                                                              }}
                                                              onMouseEnter={(
                                                                e
                                                              ) =>
                                                                (e.currentTarget.style.transform =
                                                                  "scale(1.2)")
                                                              }
                                                              onMouseLeave={(
                                                                e
                                                              ) =>
                                                                (e.currentTarget.style.transform =
                                                                  "scale(1)")
                                                              }
                                                            />
                                                          </center>
                                                        </Link>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Link
                                                          to={`/product/${product.Id}/${product.Slug}`}
                                                        >
                                                          <center>
                                                            <img
                                                              src="/assets/images/logo1.png"
                                                              style={{
                                                                width: "auto",
                                                                height: "auto",
                                                                maxWidth:
                                                                  "100%",
                                                                maxHeight:
                                                                  "270px",
                                                              }}
                                                            />
                                                          </center>
                                                        </Link>
                                                      </>
                                                    )}
                                                  </div>
                                                  <div
                                                    className="text"
                                                    style={{ color: "gray", marginLeft: '10%', marginTop: '10px' }}
                                                  >
                                                    <h2 className="h5">
                                                      {product.Name}
                                                      <br />({product.ArticleNo}
                                                      )
                                                    </h2>
                                                    <h2 className="h5"></h2>
                                                  </div>
                                                </div>
                                              </article>
                                            </div>
                                          ))
                                      ) : (
                                        <div>
                                          No products available for this
                                          category.
                                        </div>
                                      )}

                                      {/* </div> */}
                                    </div>{" "}
                                    {/*/row*/}
                                  </div>{" "}
                                  {/*/categories*/}
                                </div>{" "}
                                {/*/box-2*/}
                              </div>{" "}
                              {/*/navbar-box*/}
                            </div>{" "}
                            {/*/navbar-dropdown*/}
                          </li>
                        ))}
                      </>
                    )}

                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link to="/about">About</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <Link to="/contact">Contact</Link>*/}
                    {/*</li>*/}
                    {/* <li>
                      <Link to="/cartItems">
                        <i
                          className="icon icon-cart"
                          style={{ fontSize: "2rem", color: "#ffbb00" }}
                        />
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={toggleSearchBar}
                        style={{ background: "none", border: "none" }}
                      >
                        <i
                          className="icon icon-magnifier"
                          style={{ fontSize: "2rem", color: "#ffbb00" }}
                        />
                      </button>
                    </li> */}
                  </ul>
                </div>{" "}
                {/*/floating-menu*/}
              </div>{" "}
              {/*/navigation-main*/}
              {/* ==========  Search wrapper ========== */}
              {showSearchBar && (
                <div className="row">
                  <div className="col-md-8"></div>
                  <form onSubmit={handleSearchSubmit}>
                    <div className="col-md-3">
                      <input
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
                      />
                    </div>
                    <div className="col-md-1">
                      <button className="btn btn-main btn-search" type="submit">
                        Search!
                      </button>
                    </div>
                  </form>

                  {/* Search form */}
                </div>
              )}
            </div>{" "}
            {/*/container*/}
          </nav>
        </>
      ) : (
        <>
          <nav className="navbar-fixed">
            <div
              className="container"
              style={{ marginRight: "5%", marginLeft: "5%" }}
            >
              {/* ==========  Top navigation ========== */}
              <div className="navigation navigation-top clearfix">
                <ul></ul>
              </div>{" "}
              {/*/navigation-top*/}
              {/* ==========  Main navigation ========== */}
              <div className="navigation navigation-main">
                {/* Setup your logo here */}
                <div className="container" style={{ marginLeft: "inherit" }}>
                  <Link to="/" className="logo">
                    <img src="/assets/images/logo1.png" alt="img" />
                  </Link>
                </div>
                {/* Mobile toggle menu */}
                <a href="#" className="open-menu">
                  <i className="icon icon-menu" />
                </a>
                {/* Convertible menu (mobile/desktop) */}
                <div className="floating-menu">
                  {/* Mobile toggle menu trigger */}
                  <div className="close-menu-wrapper">
                    <span className="close-menu">
                      <i className="icon icon-cross" />
                    </span>
                  </div>

                  <ul>{/* Menu items go here */}</ul>
                </div>{" "}
                {/*/floating-menu */}
              </div>{" "}
              {/*/navigation-main */}
            </div>{" "}
            {/*/container */}
          </nav>
        </>
      )}
    </>
  );
}

// import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';

// export default function Header() {
//   const [searchTerm, setSearchTerm] = useState(''); // State for search input
//   const [showSearchBar, setShowSearchBar] = useState(false); // State to toggle search bar visibility
//   const navigate = useNavigate(); // Hook to programmatically navigate

//   // Handle search submit
//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     if (searchTerm.trim()) {
//       // Redirect to shop page with search query
//       navigate(`/shop?search=${encodeURIComponent(searchTerm)}`);
//     }
//   };

//   // Toggle the search bar visibility
//   const toggleSearchBar = () => {
//     setShowSearchBar(!showSearchBar);
//   };

//   return (
//     <>
//       <nav className="navbar-fixed">
//         <div className="container">
//           {/* ==========  Main navigation ========== */}
//           <div className="navigation navigation-main">
//             {/* Setup your logo here */}
//             <Link to="/" className="logo">
//               <img src="/assets/images/logo1.png" alt="img" />
//             </Link>
//             {/* Mobile toggle menu */}
//             <a href="#" className="open-menu">
//               <i className="icon icon-menu" />
//             </a>
//             {/* Convertible menu (mobile/desktop) */}
//             <div className="floating-menu">
//               {/* Mobile toggle menu trigger */}
//               <div className="close-menu-wrapper">
//                 <span className="close-menu">
//                   <i className="icon icon-cross" />
//                 </span>
//               </div>
//               <ul>
//                 <li><Link to="/">Home</Link></li>
//                 <li><Link to="/shop">Shop</Link></li>
//                 <li><Link to="/about">About</Link></li>
//                 <li><Link to="/contact">Contact</Link></li>
//                 <li>
//                   <Link to="/cartItems">
//                     <i className="icon icon-cart" style={{ fontSize: '2rem', color: '#ffbb00' }} />
//                   </Link>
//                 </li>
//                 {/* Search button next to the cart icon */}
//                 <li>
//                   <button onClick={toggleSearchBar} style={{ background: 'none', border: 'none' }}>
//                     <i className="icon icon-magnifier" style={{ fontSize: '2rem', color: '#ffbb00' }} />
//                   </button>
//                 </li>
//               </ul>
//             </div> {/*/floating-menu*/}
//           </div> {/*/navigation-main*/}

//           {/* ==========  Search wrapper (visible when search icon is clicked) ========== */}
//           {showSearchBar && (
//             <div>
//               {/* Search form */}
//               <form onSubmit={handleSearchSubmit}>
//                 <input
//                   className="form-control"
//                   placeholder="Search..."
//                   value={searchTerm}
//                   onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
//                 />
//                 <button className="btn btn-main btn-search" type="submit">Go!</button>
//               </form>
//             </div>
//           )}
//         </div> {/*/container*/}
//       </nav>
//     </>
//   );
// }
