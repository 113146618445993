
import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link } from 'react-router-dom'
import Category from '../FetchApi/Category'
import Products from '../FetchApi/Products';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


export default function Shop() {
  const { loadProducts, products } = Products();
  const { loadCategory, categories } = Category();

  const pagination = [1, 2, 3, 4, 5];
  const [selectedCategory, setSelectedCategory] = useState();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8;

  // Add null check before calculating total pages
  const totalPages = filteredProducts && filteredProducts.length ? Math.ceil(filteredProducts.length / productsPerPage) : 1;

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts && filteredProducts.length > 0 ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct) : [];

  // Search State
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermCat, setSearchTermCat] = useState();

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const changeSelectedCategory = (id, searchTermCat) => {
    setSelectedCategory(id);
    setCurrentPage(1);

    if (id) {
      const newFilteredProducts = products?.products?.filter(product => product.SubCategoryId === id) || [];
      setFilteredProducts(newFilteredProducts);
    } else {
      setFilteredProducts(products.products || []);
    }

    // if (searchTermCat) {
    //   const newFilteredProducts = products?.products?.filter(product => product.SubCategoryId === searchTermCat) || [];
    //   setFilteredProducts(newFilteredProducts);
    // } else {
    //   setFilteredProducts(products.products || []);
    // }
  };

  useEffect(() => {
    loadCategory();
    loadProducts();
  }, []);

  useEffect(() => {
    if (products?.products) {
      setFilteredProducts(products.products);
    }
  }, [products]);

  // Filter products by search term
  const location = useLocation();

  useEffect(() => {
    // Extract search term from the URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search') || '';
    setSearchTerm(searchQuery); // Set search term based on query param
    const searchParams1 = new URLSearchParams(location.search);
    const searchQuery1 = searchParams.get('category') || '';
    setSearchTermCat(searchQuery1); // Set search term based on query param
  }, [location]);

  useEffect(() => {
    if (searchTerm) {
      const searchResults = products?.products?.filter(product =>
        product.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.ArticleNo.toLowerCase().includes(searchTerm.toLowerCase())
      ) || [];
      setFilteredProducts(searchResults);
    } else {
      setFilteredProducts(products?.products || []);
    }
    if (searchTermCat) {
      console.log(searchTermCat);

      const newFilteredProducts = products?.products?.filter(product => product.SubCategoryId === parseInt(searchTermCat)) || [];
      setFilteredProducts(newFilteredProducts);
    }
  }, [searchTerm, products, searchTermCat]);

  const hasCategories = Array.isArray(categories?.categories);
  const hasProducts = Array.isArray(products?.products);

  return (
    <>
      <Helmet>
        <title>Shop | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Shop | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <section className="main-header" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
        <header>
          <div className="container">
            <h1 className="h2 title">Shop</h1>
            <ol className="breadcrumb breadcrumb-inverted">
              <li><Link to="/"><span className="icon icon-home" /></Link></li>
              <li><Link className="active" to="/shop">Shop</Link></li>
            </ol>
          </div>
        </header>
      </section>

      <section className="owl-icons-wrapper">
        <header className="hidden">
          <h2>Product categories</h2>
        </header>
      </section>

      <section className="products">
        <div style={{ marginLeft: '5%', marginRight: '5%' }}>
          <header className="hidden">
            <h3 className="h3 title">Product category grid</h3>
          </header>

          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="filters">
                <div className="filter-box active">
                  <div className="title">Categories</div>
                  <div className="filter-content">
                    {hasCategories && categories.categories.length > 0 ? (
                      <>
                        <span className="checkbox">
                          <input
                            type="radio"
                            name="radiogroup-type"
                            id="typeIdAll"
                            defaultChecked={selectedCategory === null}
                            onChange={() => changeSelectedCategory(null)}
                          />
                          <label htmlFor="typeIdAll">All</label>
                        </span>

                        {categories.categories.map((category, index) => (
                          <span className="checkbox" key={index}>
                            <input
                              type="radio"
                              name="radiogroup-type"
                              id={`typeId${category.Id}`}
                              defaultChecked={index === 0 && selectedCategory === null}
                              onChange={() => changeSelectedCategory(category.Id)}
                            />
                            <label htmlFor={`typeId${category.Id}`}>{category.Name}</label>
                          </span>
                        ))}
                      </>
                    ) : (
                      <p>No categories available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9 col-xs-12">
              {/* Search Input */}
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    placeholder="Search for products..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control"
                    style={{ marginBottom: '20px' }}
                  />
                </div>
              </div>

              <div className="row">
                {hasProducts && currentProducts.length > 0 ? (
                  currentProducts.map((product, index) => (
                    <React.Fragment key={product.Id}>
                  <div className="col-md-3 col-xs-6 col-sm-4">
                    <article>
                      <div className="info">
                        {/* <span className="add-favorite added">
        <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
      </span> */}
                        <span>
                          <Link to={`/product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link>
                        </span>
                      </div>
                      <Link to={`/product/${product.Id}/${product.Slug}`}>
                        <div className="btn btn-add">
                          <i className="icon icon-cart" />
                        </div>
                      </Link>
                      <div className="figure-grid" style={{ marginTop: '10%' }}>
                        <div className="image">
                          {product.Thumbnai ? (
                            <Link to={`/product/${product.Id}/${product.Slug}`}>
                              <center>
                                <img
                                  src={`${IMG_BASE_URL}${product.Thumbnai}`}
                                  style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '200px', transition: 'transform 0.3s ease' }}
                                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
                                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                />
                              </center>
                            </Link>
                          ) : (
                            <Link to={`/product/${product.Id}/${product.Slug}`}>
                              <center>
                                <img src="/assets/images/logo1.png" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px' }} />
                              </center>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="text" style={{ marginLeft: '5%' ,marginRight: '5%'}}>
                        <h2 className="title" style={{ fontSize: '12px'}}><Link to={`product/${product.Id}/${product.Slug}`}>{product.Name}</Link></h2>
                        <h2 className="title" style={{ fontSize: '12px'}}><Link to={`product/${product.Id}/${product.Slug}`}>({product.ArticleNo})</Link></h2>
                        <sup><h6>Rs {product.SalePrice}</h6></sup>
                        {/* <span className="description clearfix">{product.LongDescription}</span> */}
                      </div>
                    </article>
                  </div>
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    {Array.from({ length: 8, }, (_, index) => (
                      <div className="col-md-3 col-xs-6 col-sm-4">
                        <article>
                          <div className="info">
                            {/* <span className="add-favorite added">
                    <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                  </span> */}
                            <span>
                              {/* <Link to={`product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link> */}
                            </span>
                          </div>
                          {/* <Link to={`product/${product.Id}/${product.Slug}`}> */}
                          <div className="btn btn-add">
                            <i className="icon icon-cart" />
                          </div>
                          {/* </Link> */}
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="figure-grid" style={{ marginTop: '10%' }}>
                            <div className="image">
                              <div className="loading-image1 skeleton" />
                              {/* <Link to={`product/${product.Id}/${product.Slug}`}>
                      <center><img src={`${IMG_BASE_URL}${encodeURIComponent(product.Thumbnai)}`}  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center>
                    </Link> */}
                            </div>
                            <br />
                            <br />
                            <div className="text">
                              <h2 className="title h3"><div className="loading-title skeleton" /></h2>
                              <h2 className="title h4"><div className="loading-title2 skeleton" /></h2>
                              {/* <sub>Rs {product.SalePrice}</sub> */}
                              <div className="loading-title3 skeleton" />
                              <span className="description clearfix"><div className="loading-title skeleton" /></span>

                            </div>
                          </div>
                        </article>
                      </div>
                    ))}

                  </>
                )}
              </div>

              <div className="pagination-wrapper">
                <ul className="pagination">
                  <li className={currentPage === 1 ? 'disabled' : ''}>
                    <a href="#!" aria-label="Previous" onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  {[...Array(totalPages)].map((_, page) => (
                    <li key={page + 1} className={currentPage === page + 1 ? 'active' : ''}>
                      <a href="#!" onClick={() => handleClick(page + 1)}>{page + 1}</a>
                    </li>
                  ))}
                  <li className={currentPage === totalPages ? 'disabled' : ''}>
                    <a href="#!" aria-label="Next" onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
