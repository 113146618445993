import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function () {
  return (
    <>
    <Helmet>
      <title>Terms & Conditions | Citizen Plastic</title>
      <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      <meta property="og:title" content="Terms & Conditions | Citizen Plastic" />
      <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
  <LoadScripts />
  <div>
<section className="main-header main-header-blog" style={{backgroundImage: 'url(assets/images/breadcrumb.jpg)'}}>
  <header>
    <div className="container text-center">
      <h2 className="h2 title">Terms & Conditions</h2>
      {/* <p className="subtitle">Track your Order</p> */}
    </div>
  </header>
</section>
<section className="login-wrapper login-wrapper-page">
    <div className="container">
    {/* <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Track Your Order by Entrying the Order Now</h2>
              </div>
            </div>
          </header> */}
          <div className="row">
            <div className="col-md-12">
            <ol style={{ fontSize: '25px' }}>
            <li>Terms and conditions apply to all the sections of this website.</li>
            <li>You are free to use most part of the website without getting registration (creating account with us) or disclosing any information but to complete the order process we need your email address, phone number and postal address.</li>
            <li>Taxes are included in the price of products.</li>
            <li>For details of remaining items, you may call on UAN: 055-111-936-936 or MOB: +92 321 7418411 (9:00AM to 5:00PM).</li>
            <li>These terms can be revised or changed without giving any prior notice by citizen.com.pk.</li>
            <li>We assume that you have read and accepted the terms &amp; conditions before any kind of online shopping with us.</li>
            <li>	In case of refund, claim will be accepted or rejected according to the refund policy of citizen.com.pk.</li>
            <li>	In case of any illegal activity we may, without any prior notice terminate the order or registration with us.</li>
            <li>	Customers will be able to cancel the order from their control panel till the order status shows “Pending”. We will not be able to cancel the order once its status has been changed to “Processed".</li>
            <li>	Before to order any product, please make sure product technically fulfills your need and contains no illegal ingredient (e.g. wine, toxins etc.) or content.</li>
            <li>	There is no warranty of our furniture products.</li>
        </ol>
            </div>
          </div>
    </div>
  </section>
</div>
</>
  )
}
