import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useNavigate } from 'react-router-dom'
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import CreateCustomer from '../FetchApi/CreateCustomer';
import { Helmet } from 'react-helmet';
import City from '../FetchApi/City';
import StoreApi from '../Controllers/StoreApi';


export default function Checkout2() {
  const { fetchCities, cities } = City();
  const [selectedCity, setSelectedCity] = useState();
  const { addCustomer, customer, createOrder } = CreateCustomer();
  const { cartItems } = ManageCart();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [sfirstName, setsFirstName] = useState('');
  const [slastName, setsLastName] = useState('');
  const [scompanyName, setsCompanyName] = useState('');
  const [szipCode, setsZipCode] = useState('');
  const [scity, setsCity] = useState('');
  const [semail, setsEmail] = useState('');
  const [sphone, setsPhone] = useState('');
  const [saddress, setsAddress] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState({});
  const [selectedPayment, setSelectedPayment] = useState(1);
  const [same, setSame] = useState(true)
  const navigate = useNavigate();
  const [showOtherCityInput, setShowOtherCityInput] = useState(false);
  const [showOtherCityInput1, setShowOtherCityInput1] = useState(false);
  const [otherCity, setOtherCity] = useState('');
  const [othersCity, setOthersCity] = useState('');
  const [discount, setDiscount] = useState(0);
  const { http } = StoreApi();
  const [couponCode, setCouponCode] = useState('');
  const [promoCode, setPromoCode] = useState(false);
  const [orderTotal, setOrderTotal] = useState(0); // Add this state for order total
  const [shippingCharges, setShippingCharges] = useState(0);
  const [cityFS, setCityFS] = useState('');
  const calculateShippingCharges = (selectedCity, totalBilling) => {
    if (cities.City && Array.isArray(cities.City)) {
      const city = cities.City.find(city => city.Name === selectedCity);
      if (city && totalBilling < city.ShippingRule) {
        return city.ShippingCharges || 0;
      }
    }
    return 0;
  };
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setsCity(selectedCity);
    setShowOtherCityInput(selectedCity === 'Others');
      setCityFS(selectedCity);
      const charges = calculateShippingCharges(selectedCity, totalBilling);
      setShippingCharges(charges);  
 

  };
  const handleCityChange1 = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);
    setShowOtherCityInput1(selectedCity === 'Others');
    if(same) {
    setCityFS(selectedCity);

      const charges = calculateShippingCharges(selectedCity, totalBilling);
      setShippingCharges(charges);    
    }

 
  };
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);

  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };
  const validateEmail1 = (semail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(semail);
  };

  const validatePhone1 = (sphone) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(sphone);
  };

  const handleCreateAccount = () => {
    // console.log('gfv');

    const newErrors = {};

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!zipCode) newErrors.zipCode = 'Zip code is required';
    if (!city) newErrors.city = 'City is required';
    if (!address) newErrors.address = 'Address is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!validatePhone(phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    if (!isChecked) {
      newErrors.isChecked = 'You must accept the terms and conditions';
    }

    setErrors(newErrors);

    if (!same) {

      const same = false;
      const new1Errors = {};

      if (!sfirstName) new1Errors.firstName = 'First name is required';
      if (!slastName) new1Errors.lastName = 'Last name is required';
      if (!szipCode) new1Errors.zipCode = 'Zip code is required';
      if (!saddress) new1Errors.address = 'Address is required';
      if (!semail) {
        new1Errors.email = 'Email is required';
      } else if (!validateEmail1(semail)) {
        new1Errors.email = 'Please enter a valid email address';
      }
      if (!sphone) {
        new1Errors.phone = 'Phone number is required';
      } else if (!validatePhone1(sphone)) {
        new1Errors.phone = 'Please enter a valid phone number';
      }


      setErrors1(new1Errors);

      if (Object.keys(new1Errors).length === 0 && Object.keys(newErrors).length === 0) {
        const cityToUse2 = city === 'Others' ? otherCity : city;


        const cityToUse1 = scity === 'Others' ? othersCity : scity;
        addCustomer(firstName, lastName, companyName, zipCode, cityToUse2, email, phone, address, totalBilling, totalQuantity, selectedPayment, sfirstName, slastName, scompanyName, szipCode, cityToUse1, semail, sphone, saddress, same, couponCode, discount, shippingCharges);
      }
    } else {
      const same = true;
      if (Object.keys(newErrors).length === 0) {
        const cityToUse2 = city === 'Others' ? otherCity : city;

        addCustomer(firstName, lastName, companyName, zipCode, cityToUse2, email, phone, address, totalBilling, totalQuantity, selectedPayment, sfirstName, slastName, scompanyName, szipCode, scity, semail, sphone, saddress, same, couponCode, discount, shippingCharges);
      }
    }
  }
  async function decryptCouponCode(encrypted, iv, key) {
    const decrypted = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encrypted
    );
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  }
  const back = () => {

    navigate('/cartItems');
  }

  const fetchDiscount = async (couponCode) => {
    try {
      const response = await http.post("/checkCoupon", { coupon: couponCode });
      if (response.status === 200 && response.data.Coupon) {
        setDiscount(response.data.Coupon.DiscountValue);
        setPromoCode(true)
      } else {
        setDiscount(0);
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
      setDiscount(0);
    }
  };

  useEffect(() => {
    const storedIv = localStorage.getItem('coupon_iv');
    const storedEncrypted = localStorage.getItem('coupon_encrypted');
    const storedKey = localStorage.getItem('coupon_key');
    const storedCouponCode = localStorage.getItem('coupon_code'); // Retrieve the stored coupon code

    if (storedCouponCode) {
      setCouponCode(storedCouponCode); // Set the coupon code state
    }

    if (storedIv && storedEncrypted && storedKey) {
      const iv = new Uint8Array(JSON.parse(storedIv));
      const encrypted = new Uint8Array(JSON.parse(storedEncrypted));
      const key = crypto.subtle.importKey('jwk', JSON.parse(storedKey), { name: 'AES-GCM' }, true, ['decrypt']);

      key.then(async (importedKey) => {
        const decryptedCouponCode = await decryptCouponCode(encrypted.buffer, iv, importedKey);
        fetchDiscount(decryptedCouponCode);
      });
    }
  }, []);

  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const totalQuantity = cartItems.reduce((acc, item) => acc + (item.quantity), 0);
  const shipping = 0;  // 30,00 in Rs.
  const gst = 0;       // 59,00 in Rs.
  const finalAmount = totalBilling + shippingCharges + (gst / 100);
  useEffect(() => {
    fetchCities();
  }, []);
  useEffect(() => {
    const selectedCity = cityFS; // Replace with your selected city state
    const charges = calculateShippingCharges(selectedCity, totalBilling);
    setShippingCharges(charges);

  }, [city, orderTotal]);
  return (
    <>
      <Helmet>
        <title>Information | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Information | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      {/* <section className="main-header" style={{ backgroundImage: 'url(/assets/images/gallery-2.jpg)' }}>
        <header>
          <div className="container text-center">
            <h2 className="h2 title">Checkout</h2>
        
          </div>
        </header>
      </section> */}
      <section style={{ background: 'black' }}>
      </section>
      {/* ========================  Step wrapper ======================== */}
      <div className="step-wrapper">
        <div className="container">
          <div className="stepper">
            <ul className="row">
              <li className="col-md-3 active">
                <span data-text="Cart items" />
              </li>
              <li className="col-md-3 active">
                <span data-text="Information" />
              </li>
              <li className="col-md-3">
                <span data-text="Confirmation" />
              </li>
              <li className="col-md-3">
                <span data-text="Receipt" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* ========================  Checkout ======================== */}
      <section className="checkout">
        <div style={{ marginLeft: '6%', marginRight: '6%' }}>
          <div className="row">
            <div className="col-md-9 col-sm-12">
              <header className="hidden">
                <h3 className="h3 title">Checkout - Step 2</h3>
              </header>
              {/* ========================  Cart navigation ======================== */}
              <div className="clearfix">
                <div className="row">
                  <div className="col-xs-6">
                    <Link to="/cartItems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link>
                  </div>
                  <div className="col-xs-6 text-right">
                    <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button>
                  </div>
                </div>
              </div>
              {/* ========================  Delivery ======================== */}
              <div className="cart-wrapper">
                <div className="note-block">
                  <div className="row">
                    {/* === left content === */}
                    <div className="col-md-6">
                      {/* === login-wrapper === */}
                      <div className="login-wrapper">
                        <div className="white-block">

                          <div className="login-block login-block-signup">

                            <div className="h4">Billing Information</div>
                            <hr />
                            <div className="row">

                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="First name: *"
                                  />
                                  {errors.firstName && <div className="text-warning">{errors.firstName}</div>}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Last name: *"
                                  />
                                  {errors.lastName && <div className="text-warning">{errors.lastName}</div>}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Address *"
                                  />
                                  {errors.address && <div className="text-warning">{errors.address}</div>}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                    placeholder="Postal Code: *"
                                  />
                                  {errors.zipCode && <div className="text-warning">{errors.zipCode}</div>}
                                </div>
                              </div>
                              {cities.City && (
                                <div className="col-md-8">
                                  <div className="form-group">

                                    <select
                                      id="city-select"
                                      className="form-control"
                                      value={city}
                                      onChange={handleCityChange1}
                                    >
                                      <option value="">Select a City *</option>
                                      {cities.City.map((city, index) => (
                                        <option key={index} value={city.Name}>
                                          {city.Name}
                                        </option>
                                      ))}
                                    </select>
                                    {errors.city && <div className="text-warning">{errors.city}</div>}
                                  </div>
                                </div>
                              )}

                              {showOtherCityInput1 && (
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={otherCity}
                                      onChange={(e) => setOtherCity(e.target.value)}
                                      placeholder="Enter your city *"
                                      required
                                    />
                                    {errors.city && <div className="text-warning">{errors.city}</div>}
                                  </div>
                                </div>
                              )}

                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email: *"
                                  />
                                  {errors.email && <div className="text-warning">{errors.email}</div>}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone: *"
                                  />
                                  {errors.phone && <div className="text-warning">{errors.phone}</div>}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <span className="checkbox">
                                  <input
                                    type="checkbox"
                                    id="checkBoxId2"
                                    checked={same}
                                    onChange={(e) => setSame(e.target.checked)}
                                    required
                                  />
                                  <label htmlFor="checkBoxId2">Use Same Shipping address as Billing Address
                                  </label>
                                </span>



                              </div>
                            </div>

                            {!same && <>
                              <div className="h4">Shipping Information</div>
                              <hr />
                              <div className="row">

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={sfirstName}
                                      onChange={(e) => setsFirstName(e.target.value)}
                                      placeholder="First name: *"
                                    />
                                    {errors1.firstName && <div className="text-warning">{errors1.firstName}</div>}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={slastName}
                                      onChange={(e) => setsLastName(e.target.value)}
                                      placeholder="Last name: *"
                                    />
                                    {errors1.lastName && <div className="text-warning">{errors1.lastName}</div>}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={saddress}
                                      onChange={(e) => setsAddress(e.target.value)}
                                      placeholder="Address"
                                    />
                                    {errors1.address && <div className="text-warning">{errors1.address}</div>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={szipCode}
                                      onChange={(e) => setsZipCode(e.target.value)}
                                      placeholder="Postal Code: *"
                                    />
                                    {errors1.zipCode && <div className="text-warning">{errors1.zipCode}</div>}
                                  </div>
                                </div>
                                {cities.City && (
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <select
                                        id="city-select"
                                        className="form-control"
                                        value={scity}
                                        onChange={handleCityChange}
                                      >
                                        <option value="">Select a City *</option>
                                        {cities.City.map((city, index) => (
                                          <option key={index} value={city.Name}>
                                            {city.Name}
                                          </option>
                                        ))}
                                        {/* <option value="Others">Others</option> */}
                                      </select>
                                      {errors1.city && <div className="text-warning">{errors1.city}</div>}
                                    </div>
                                  </div>
                                )}

                                {showOtherCityInput && (
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={othersCity}
                                        onChange={(e) => setOthersCity(e.target.value)}
                                        placeholder="Enter your city *"
                                        required
                                      />
                                      {errors1.city && <div className="text-warning">{errors1.city}</div>}
                                    </div>
                                  </div>
                                )}

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={semail}
                                      onChange={(e) => setsEmail(e.target.value)}
                                      placeholder="Email: *"
                                    />
                                    {errors1.email && <div className="text-warning">{errors1.email}</div>}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={sphone}
                                      onChange={(e) => setsPhone(e.target.value)}
                                      placeholder="Phone: *"
                                    />
                                    {errors1.phone && <div className="text-warning">{errors1.phone}</div>}
                                  </div>
                                </div>

                                <div className="col-md-12">

                                </div>
                              </div>
                            </>}
                            <div className="col-md-12">
                              <hr />
                              <span className="checkbox">
                                <input
                                  type="checkbox"
                                  id="checkBoxId1"
                                  checked={isChecked}
                                  onChange={(e) => setIsChecked(e.target.checked)}
                                  required
                                />
                                <label htmlFor="checkBoxId1">
                                  I have read and accepted the <Link to="/TermsCondition#">terms</Link>, as well as read and understood our terms of business conditions.
                                </label>
                                {errors.isChecked && <div className="text-warning">{errors.isChecked}</div>}
                              </span>
                              <hr />

                              <p>Please allow three working days for the payment confirmation to reflect in your <a href="#">online account</a>. Once your payment is confirmed, we will generate your e-invoice, which you can view/print from your account or email.</p>

                            </div>
                          </div> {/*/signup*/}
                        </div>
                      </div> {/*/login-wrapper*/}
                    </div> {/*/col-md-6*/}
                    {/* === right content === */}
                    <div className="col-md-6">
                      <div className="white-block">
                        <div className="h4">Choose payment</div>
                        <hr />
                        <span className="checkbox">
                          <input
                            type="radio"
                            id="paymentID1"
                            name="paymentOption"
                            value={1}
                            defaultChecked={true}
                            onChange={handlePaymentChange}
                          />
                          <label htmlFor="paymentID1">
                            <strong>Cash On Delivery(COD)</strong> <br />
                            <small>
                              Cash on delivery at doorstep where our stores exist (Only specific areas where we have our official outlets: Gujranwala, Rawalpindi, Islamabad, Faisalabad)
                            </small>
                          </label>
                        </span>
                        <span className="checkbox">
                          <input
                            type="radio"
                            id="paymentID2"
                            name="paymentOption"
                            value={2}
                            onChange={handlePaymentChange}
                          />
                          <label htmlFor="paymentID2">
                            <strong>Pickup Payment</strong> <br />
                            <small>Where customers pay when they pick up their orders from our outlets.</small>
                            <small><Link to="/storeLocator">Store Locator.</Link></small>
                          </label>
                        </span>
                        <span className="checkbox">
                          <input
                            type="radio"
                            id="paymentID3"
                            name="paymentOption"
                            value={3}
                            onChange={handlePaymentChange}
                          />
                          <label htmlFor="paymentID3">
                            <strong>Pay via bank transfer</strong> <br />
                            <small>You can make payments directly into our bank account and email or WhatsApp the bank receipt to us.</small>
                          </label>
                        </span>
                        <hr />
                        <p>
                          <ul>
                            <li>Free delivery in Gujranwala.</li>
                            <li>Free delivery on orders over Rs. 10,000 in Islamabad, Rawalpindi, Gujrat and Faisalabad.</li>
                            <li>Rs. 1,000 delivery charge for orders under Rs. 10,000 in Islamabad, Rawalpindi, Gujrat, and Faisalabad.</li>
                            <li>Rs. 1,000 delivery charges for all other cities along with advance payment in Punjab only.</li>
                            <li>For delivery charges in Sindh, Khyber-Pakhtunkhwa, Balochistan, Azad Kashmir and Gilgit Baltistan to be decided on phone call.</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ========================  Cart wrapper ======================== */}
              <div className="cart-wrapper">
                {/*cart header */}
                <div className="cart-block cart-block-header clearfix">

                  <div>
                    <span>Product</span>
                  </div>

                  <div>
                    <span>Name</span>
                  </div>


                  <div className='text-left'>
                    <span>Color</span>
                  </div>

                  <div className="text">
                    <span>Quantity</span>
                  </div>
                  <div className="text-right">
                    <span>Price</span>
                  </div>
                </div>
                {/*cart items*/}
                <div className="clearfix">
                  {cartItems.length > 0 ? (
                    cartItems.map((item) => (
                      <div className="cart-block cart-block-item clearfix" key={item.Id}>
                        <div className="image">
                          <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${item.attimage}`} alt="pic" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
                        </div>
                        <div className="title">
                          <div className="h6">{item.Name} ({item.ArticleNo})</div>
                          <div>{item.scName}</div>
                        </div>
                        <div className="title">
                          <div className="h6">{item.color}</div>
                        </div>
                        <div className="quantity">
                          <strong>{item.quantity}</strong>
                        </div>
                        <div className="price">
                          <span className="final h6">Rs.  {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                          {/* <span className="discount">Rs. 2.666</span> */}
                        </div>
                      </div>
                    ))
                  ) : (<>
                    {back && back()} {/* Call the back function if it's defined */}
                    <div className='cart-block cart-block-item clearfix'>
                      <h2 align="center">Empty Cart</h2>
                    </div>
                  </>
                  )}
                </div>
                {/*cart prices */}
                <div className="clearfix">
                  <div className="cart-block cart-block-footer clearfix">
                    <div>
                      <strong>Discount</strong>
                    </div>
                    <div>
                      <span>Rs. {discount}</span>
                    </div>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="cart-block cart-block-footer clearfix">
                    <div>
                      <strong>Shipping Charges</strong>
                    </div>
                    <div>
                      <span>Rs. {shippingCharges}</span>
                    </div>
                  </div>
                </div>
                {/*cart final price */}
                <div className="clearfix">
                  <div className="cart-block cart-block-footer clearfix">
                    <div>
                      {promoCode && (
                        <strong style={{ color: 'green' }}>Promo code included!</strong>
                      )}


                    </div>
                    <div>
                      <div className="h2 title">Rs. {finalAmount.toFixed(2) - discount}</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ========================  Cart navigation ======================== */}
              <div className="clearfix">
                <div className="row">
                  <div className="col-xs-6">
                    <Link to="/cartitems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link>
                    <Link to="/shop" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Continue Shopping</Link>

                  </div>
                  <div className="col-xs-6 text-right">
                    <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div style={{ marginTop: '12%'}}>
                <header className="hidden">
                  <h3 className="h3 title">Checkout - Step 1</h3>
                </header>
                <div className="clearfix">
                <div className="row">
                  <div className="col-xs-6">
                    {/* <Link to="/cartItems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link> */}
                  </div>
                  <div className="col-xs-6 text-right">
                    {/* <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button> */}
                  </div>
                </div>
              </div>
                {/* ========================  Cart wrapper ======================== */}
                <div className="cart-wrapper">
                  {/*cart header */}
                  <div className="cart-block cart-block-header clearfix">
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <span>Customer Service</span>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className='cart-block cart-block-item clearfix' >
                     
                      <ul style={{ marginLeft: '4%', marginRight: '4%', marginTop: '10%' }}>
                        <li><span className="icon icon-phone-handset" /> +92-333-0348378</li>
                        <li><span className="icon icon-phone-handset" /> +92-55-111-936-936</li>
                        <li><span className="icon icon-envelope" /> shop@citizen.com.pk</li>
                        <hr />
                        <p>Sun-Thu and Sat: 09:00 AM – 05:00 PM <br /> Friday: Closed</p>
                      </ul>
                     
                      
                    </div>
                  </div>

                </div></div>
            </div>
          </div>

        </div> {/*/container*/}
      </section>
    </>

  )
}
