import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import StoreApi from '../Controllers/StoreApi';


export default function Checkout4() {
  const { http} = StoreApi();

  const [customer, setCustomer] = useState({});
  const [orderNumber, setOrderNumber] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalQuantity, setTotalQuantity] = useState();
  const [invoice, setInvoice] = useState(null); 
  const navigate = useNavigate();
  const getInvoices = async() => {

    const orderId = localStorage.getItem('oldorderNumber');
    if(orderId) { 
    console.log(orderId);
    await http.post('/getOrderInvoice', { 
     orderId: orderId
    }, {
  })
  .then(response => {
      if (response.status === 200) {
        setInvoice(response.data);
        console.log(invoice);

      } else {
        console.error("Fetching Order failed:", response.data.message);
      }
    })
    .catch(error => {
      console.error("Error during fetching Order:", error.message);
      // localStorage.clear();
      // navigate('/login');
    })
    .finally(() => {
    
  });      
}  }
  useEffect(() => {
   
    getInvoices();
  }, []);
  // const hasOrder = Array.isArray(invoice.Order);


  return (
    <>
      <Helmet>
        <title>Receipt | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Receipt | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
     <LoadScripts />

<div>
<section style={{background: 'black' }}>
</section>
  {/* ========================  Step wrapper ======================== */}
  <div className="step-wrapper">
    <div className="container">
      <div className="stepper">
        <ul className="row">
          <li className="col-md-3 active">
            <span data-text="Cart items" />
          </li>
          <li className="col-md-3 active">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3 active">
                  <span data-text="Confirmation" />
                </li>
                <li className="col-md-3 active">
                  <span data-text="Receipt" />
                </li>
        </ul>
      </div>
    </div>
  </div>
  {/* ========================  Checkout ======================== */}
  <section className="checkout">
    <div className="container">
      <header className="hidden">
        <h3 className="h3 title">Checkout - Step 4</h3>
      </header>
      {/* ========================  Cart navigation ======================== */}
      <div className="clearfix">
        <div className="row">
          <div className="col-xs-6">
            <span className="h2 title">Your order is completed!</span>
          </div>
          <div className="col-xs-6 text-right">
            {/* <a onClick={window.print()} className="btn btn-main"><span className="icon icon-printer" /> Print</a> */}
          </div>
        </div>
      </div>
      {/* ========================  Payment ======================== */}
      <div className="cart-wrapper">
        <div className="note-block">
          <div className="row">
            {/* === left content === */}
            <div className="col-md-6">
              <div className="white-block">
                <div className="h4">Billing Info</div>
                <hr />
                {invoice ? (
                <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Name</strong> <br />
                      <span>{invoice.bcustomer.FirstName} {invoice.bcustomer.LastName}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Email</strong><br />
                      <span>{invoice.bcustomer.EmailAddress}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Phone</strong><br />
                      <span>{invoice.bcustomer.PhoneNo}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Postal Code</strong><br />
                      <span>{invoice.bcustomer.ZipCode}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>City</strong><br />
                      <span>{invoice.bcustomer.CityId}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Address</strong><br />
                      <span>{invoice.bcustomer.Address}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company name</strong><br />
                      <span>{invoice.customer.CompanyName}</span>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company phone</strong><br />
                      <span>+122 333 6665</span>
                    </div>
                  </div> */}
                </div>
                </>) : (<p>Loading ...</p>)}
                
              </div> {/*/col-md-6*/}
            </div>
            <div className="col-md-6">
              <div className="white-block">
                <div className="h4">Shipping info</div>
                <hr />
                {invoice ? (
                <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Name</strong> <br />
                      <span>{invoice.customer.FirstName} {invoice.customer.LastName}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Email</strong><br />
                      <span>{invoice.customer.EmailAddress}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Phone</strong><br />
                      <span>{invoice.customer.MobileNo}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Postal Code</strong><br />
                      <span>{invoice.customer.PostalCode}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>City</strong><br />
                      <span>{invoice.customer.CityId}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Address</strong><br />
                      <span>{invoice.customer.Address}</span>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company name</strong><br />
                      <span>{invoice.customer.CompanyName}</span>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company phone</strong><br />
                      <span>+122 333 6665</span>
                    </div>
                  </div> */}
                </div>
                </>) : (<p>Loading ...</p>)}
                
              </div> {/*/col-md-6*/}
            </div>
            {/* === right content === */}
          
          </div>
          <div className="row">
          <div className="col-md-12">
              <div className="white-block">
                <div className="h4">Order details</div>
                <hr />
                
                {invoice && invoice.Order ? 
                <>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Order no.</strong> <br />
                      <span>{invoice.Order.OrderNo}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Gross Total</strong><br />
                      <span>Rs. {invoice.Order.GrossTotal}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Total Items</strong><br />
                      <span>{invoice.nop}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Total Discount</strong><br />
                      <span>Rs. {invoice.Order.TotalDiscount}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Total Quantity</strong><br />
                      <span>{invoice.Order.Quantity}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Shipping Charges</strong><br />
                      <span>{invoice.Order.ShippingCharges}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Total Amount</strong><br />
                      <span>{invoice.Order.NetTotal}</span>
                    </div>
                  </div>
                </div>
                </> : <p>Loading ...</p>}
                {/* <div className="h4">Payment details</div>
                <hr /> */}
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Transaction time</strong> <br />
                      <span>06/30/2017 at 00:59</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Amount</strong><br />
                      <span>Rs. 1259,00</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Cart details</strong><br />
                      <span>**** **** **** 5446</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Items in cart</strong><br />
                      <span>4</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ========================  Cart wrapper ======================== */}
   
      {/* ========================  Cart navigation ======================== */}
      <div className="clearfix">
        <div className="row">
          <div className="col-xs-6">
            <span className="h2 title">Your order is completed!</span>
          </div>
          <div className="col-xs-6 text-right">
            {/* <a onClick="window.print()" className="btn btn-main"><span className="icon icon-printer" /> Print</a> */}
          </div>
        </div>
      </div>
    </div> {/*/container*/}
  </section>
</div>



    </>
  )
}
// import React, { useEffect, useState } from 'react'
// import LoadScripts from '../Components/LoadScripts'
// import { Link, useNavigate } from 'react-router-dom'
// import { Helmet } from 'react-helmet';
// import StoreApi from '../Controllers/StoreApi';

// export default function Checkout4() {
//   const { http } = StoreApi();
//   const [invoice, setInvoice] = useState(null); // State to store the invoice data
//   const navigate = useNavigate();

//   const getInvoices = async () => {
//     const orderId = localStorage.getItem('oldorderNumber');
//     if (orderId) {
//       await http.post('/getOrderInvoice', { 
//         orderId: orderId
//       })
//       .then(response => {
//         if (response.status === 200) {
//           setInvoice(response.data);
//         } else {
//           console.error("Fetching Order failed:", response.data.message);
//         }
//       })
//       .catch(error => {
//         console.error("Error during fetching Order:", error.message);
//       });
//     }
//   };

//   useEffect(() => {
//     getInvoices();
//   }, []);
//   console.log('Invoice:', invoice); // Debugging log

//   return (
//     <>
//       <Helmet>
//         <title>Receipt | Checkout | Citizen Plastic</title>
//         <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
//         <meta property="og:title" content="Receipt | Checkout | Citizen Plastic" />
//         <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
//       </Helmet>
//       <LoadScripts />

//       <div>
//         <section className="main-header" style={{ backgroundImage: 'url(assets/images/gallery-2.jpg)' }}>
//           <header>
//             <div className="container text-center">
//               <h2 className="h2 title">Checkout</h2>
//               <ol className="breadcrumb breadcrumb-inverted">
//                 <li><Link to="/"><span className="icon icon-home" /></Link></li>
//                 <li><Link to="/checkout1">Cart items</Link></li>
//                 <li><Link to="/checkout2">Delivery</Link></li>
//                 <li><Link to="/checkout3">Payment</Link></li>
//                 <li><Link className="active" to="/checkout4">Receipt</Link></li>
//               </ol>
//             </div>
//           </header>
//         </section>

//         <div className="step-wrapper">
//           <div className="container">
//             <div className="stepper">
//               <ul className="row">
//                 <li className="col-md-3"><span data-text="Cart items" /></li>
//                 <li className="col-md-3"><span data-text="Information" /></li>
//                 <li className="col-md-3"><span data-text="Confirmation" /></li>
//                 <li className="col-md-3 active"><span data-text="Receipt" /></li>
//               </ul>
//             </div>
//           </div>
//         </div>

//         <section className="checkout">
//           <div className="container">
//             <header className="hidden">
//               <h3 className="h3 title">Checkout - Step 4</h3>
//             </header>

//             <div className="clearfix">
//               <div className="row">
//                 <div className="col-xs-6">
//                   <span className="h2 title">Your order is completed!</span>
//                 </div>
//               </div>
//             </div>

//             <div className="cart-wrapper">
//               <div className="note-block">
//                 <div className="row">
//                   <div className="col-md-6">
//                     <div className="white-block">
//                       <div className="h4">Shipping info</div>
//                       <hr />
//                       {invoice && invoice.customer ? (
//                         <div className="row">
//                           <div className="col-md-6">
//                             <strong>Name</strong><br />
//                             <span>{invoice.customer.FirstName} {invoice.customer.LastName}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Email</strong><br />
//                             <span>{invoice.customer.EmailAddress}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Phone</strong><br />
//                             <span>{invoice.customer.PhoneNo}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Zip</strong><br />
//                             <span>{invoice.customer.ZipCode}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>City</strong><br />
//                             <span>{invoice.customer.CityId}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Address</strong><br />
//                             <span>{invoice.customer.Address}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Company name</strong><br />
//                             <span>{invoice.customer.CompanyName}</span>
//                           </div>
//                         </div>
//                       ) : (<p>Loading ...</p>)}
//                     </div>
//                   </div>

//                   <div className="col-md-6">
//                     <div className="white-block">
//                       <div className="h4">Order details</div>
//                       <hr />
//                       {invoice && invoice.Order ? (
//                         <div className="row">
//                           <div className="col-md-6">
//                             <strong>Order no.</strong><br />
//                             <span>{invoice.Order.OrderNo}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Amount</strong><br />
//                             <span>Rs. {invoice.Order.NetTotal}</span>
//                           </div>
//                           <div className="col-md-6">
//                             <strong>Total Items</strong><br />
//                             <span>{invoice.nop}</span>
//                           </div>
//                         </div>
//                       ) : (<p>Loading ...</p>)}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="clearfix">
//               <div className="row">
//                 <div className="col-xs-6">
//                   <span className="h2 title">Your order is completed!</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </>
//   )
// }