import React, { useState, useEffect } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useNavigate } from 'react-router-dom'

import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import CreateCustomer from '../FetchApi/CreateCustomer';
import { Helmet } from 'react-helmet';
import StoreApi from '../Controllers/StoreApi';



export default function Checkout3() {
  const [customer, setCustomer] = useState([]);
  // const [orderNo, setOrderNo] = useState();
  const { cartItems } = ManageCart();
  const { checkOut } = CreateCustomer();
  const [discount, setDiscount] = useState(0);
  const { http } = StoreApi();
  const [couponCode, setCouponCode] = useState('');
  const [promoCode, setPromoCode] = useState(false);
  const [storeSc, setStoreSc] = useState(null);
  // const [finalAmo  unt, setFinalAmount] = useState();

  const navigate = useNavigate();
  const totalBilling = cartItems.reduce((acc, item) => acc + (item.SalePrice * item.quantity), 0);
  const totalQuantity = cartItems.reduce((acc, item) => acc + (item.quantity), 0);
  const shipping = storeSc;  // 30,00 in Rs.
  const gst = 0;       // 59,00 in Rs.
  const [sc, setSc] = useState(null);
  const [finalAmount, setFinalAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);



  const completeOrder = () => {
    checkOut()
      .then(() => {
        nav(); // Navigate on successful checkout
      })
      .catch((error) => {
        console.error('Error during checkout:', error); // Handle the error
      });
  };

  const nav = () => {
    window.location.href = '/checkout/receipt'; // Redirect to the new URL
  }
  async function decryptCouponCode(encrypted, iv, key) {
    const decrypted = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encrypted
    );
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  }
  const fetchDiscount = async (couponCode) => {
    try {
      const response = await http.post("/checkCoupon", { coupon: couponCode });
      if (response.status === 200 && response.data.Coupon) {
        setDiscount(response.data.Coupon.DiscountValue);
        setPromoCode(true)
      } else {
        setDiscount(0);
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
      setDiscount(0);
    }
  };
  // const fetchsc = async (sc) => {
  //   try {
  //     setstoreSc(sc);
  //   } catch (error) {
  //     console.error("Error fetching discount:", error);
  //     setstoreSc(0);
  //   }
  // };
  // useEffect(() => {
  //   if (localStorage.getItem('sc')) {
  //     const scValue = Number(localStorage.getItem('sc'));
  //     setSc(scValue);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (sc !== null) {
  //     fetchsc(sc);

  //     const finalAmount1 = totalBilling - (discount) + (storeSc) + (gst / 100);
  //     setFinalAmount(finalAmount1); 
  //     setShow(true);
  //   }
  // }, [storeSc]);
  useEffect(() => {
    const scValue = localStorage.getItem('sc');
    if (scValue !== null) {
      setStoreSc(Number(scValue));
    }
    setLoading(false); // Set loading to false after fetching the value
  }, []);

  useEffect(() => {
    if (!loading && storeSc !== null && !isNaN(totalBilling) && !isNaN(discount)) {
      const finalAmount1 = totalBilling - discount + storeSc + gst;
      setFinalAmount(finalAmount1);
      setShow(true);
    }
  }, [storeSc, discount, totalBilling, loading]);
  useEffect(() => {
    const storedIv = localStorage.getItem('coupon_iv');
    const storedEncrypted = localStorage.getItem('coupon_encrypted');
    const storedKey = localStorage.getItem('coupon_key');
    const storedCouponCode = localStorage.getItem('coupon_code'); // Retrieve the stored coupon code
    // if(localStorage.getItem('sc')){
    //   const sc = (Number(localStorage.getItem('sc')));
    // fetchsc(sc);
    // }
    if (storedCouponCode) {
      setCouponCode(storedCouponCode); // Set the coupon code state
    }

    if (storedIv && storedEncrypted && storedKey) {
      const iv = new Uint8Array(JSON.parse(storedIv));
      const encrypted = new Uint8Array(JSON.parse(storedEncrypted));
      const key = crypto.subtle.importKey('jwk', JSON.parse(storedKey), { name: 'AES-GCM' }, true, ['decrypt']);

      key.then(async (importedKey) => {
        const decryptedCouponCode = await decryptCouponCode(encrypted.buffer, iv, importedKey);
        fetchDiscount(decryptedCouponCode);
      });
    }

  }, []);
  useEffect(() => {
    if (!localStorage.getItem("cc")) {
      navigate('/cartItems');
    }
    const cc = JSON.parse(localStorage.getItem('cc')) || {};
    // const orderNumber = localStorage.getItem('orderNumber');
    setCustomer(cc);
    // console.log(orderNumber);
    // setOrderNo(orderNumber);
  }, []);

  // useEffect(() => {
  //   if(storeSc){
  //   }
  //   }, [totalBilling, storeSc, discount]);
  const back = () => {

    navigate('/cartItems');
  }


  return (
    <>
      <Helmet>
        <title>Comfirmation | Checkout | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Comfirmation | Checkout | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />

      <div>
        <section style={{ background: 'black' }}>
        </section>
        {/* ========================  Step wrapper ======================== */}
        <div className="step-wrapper">
          <div className="container">
            <div className="stepper">
              <ul className="row">
                <li className="col-md-3 active">
                  <span data-text="Cart items" />
                </li>
                <li className="col-md-3 active">
                  <span data-text="Information" />
                </li>
                <li className="col-md-3 active">
                  <span data-text="Confirmation" />
                </li>
                <li className="col-md-3">
                  <span data-text="Receipt" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ========================  Checkout ======================== */}
        <section className="checkout">
          <div style={{ marginLeft: '6%', marginRight: '6%' }}>
            <div className="row">
              <div className="col-md-9 col-sm-12">
                <header className="hidden">
                  <h3 className="h3 title">Checkout - Step 3</h3>
                </header>
                {/* ========================  Cart navigation ======================== */}
                <div className="clearfix">
                  <div className="row">
                    <div className="col-xs-6">
                      <Link to="/checkout/information" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to Information</Link>
                    </div>
                    <div className="col-xs-6 text-right">
                      <button onClick={completeOrder} className="btn btn-main"><span className="icon icon-cart" /> Checkout</button>
                    </div>
                  </div>
                </div>
                {/* ========================  Payment ======================== */}
                <div className="cart-wrapper">
                  <div className="note-block">
                    <div className="row">

                      <div className="col-md-12">
                        <div className="white-block">
                          {/* <div className="h4">Order details</div>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <strong>Order no.</strong> <br />
                      <span>{orderNo ? {orderNo} : <p>Loading ...</p>}</span>
                    </div>
                  </div>
             
                </div> */}
                          <div className="h4">Shipping info</div>
                          <hr />
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>Name</strong> <br />
                                <span>{customer.FirstName} {customer.LastName}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>Email</strong><br />
                                <span>{customer.EmailAddress}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>Phone</strong><br />
                                <span>{customer.MobileNo}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>Postal Code</strong><br />
                                <span>{customer.PostalCode}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>City</strong><br />
                                <span>{customer.CityId}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <strong>Address</strong><br />
                                <span>{customer.Address}</span>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company name</strong><br />
                      <span>{customer.CompanyName}</span>
                    </div>
                  </div> */}
                            {/* <div className="col-md-6">
                    <div className="form-group">
                      <strong>Company phone</strong><br />
                      <span>+122 333 6665</span>
                    </div>
                  </div> */}
                          </div>
                        </div> {/*/col-md-6*/}
                      </div>
                      {/* === right content === */}
                      {/* <div className="col-md-6">
              <div className="white-block">
                <div className="h4">Choose payment</div>
                <hr />
                <span className="checkbox">
                  <input type="radio" id="paymentID1" name="paymentOption" defaultChecked="checked" />
                  <label htmlFor="paymentID1">
                    <strong>Cash On Delivery(COD)</strong> <br />
                    <small>Cash on delivery at doorstep where our stores exists (Only Specific Areas Where we have our official outlets
                    Our Outlet Available At Gujranwala, Lahore, Sialkot, Sargodha, Rawalpindi, Bahawalpur, Peshawar, Karachi)</small>
                  </label>
                </span>
                <span className="checkbox">
                  <input type="radio" id="paymentID2" name="paymentOption" />
                  <label htmlFor="paymentID2">
                    <strong>Pickup Payment</strong> <br />
                    <small>Where customers pay when they pick up their orders from our outlets?</small>
                  </label>
                </span>
                <span className="checkbox">
                  <input type="radio" id="paymentID3" name="paymentOption" />
                  <label htmlFor="paymentID3">
                    <strong>Pay via bank transfer</strong> <br />
                    <small>You can make payments directly into our bank account and email or whatapp the bank  receipt to us.  </small>
                  </label>
                </span>
                <hr />
                <p>Please allow three working days for the payment confirmation to reflect in your <a href="#">online account</a>. Once your payment is confirmed, we will generate your e-invoice, which you can view/print from your account or email.</p>
              </div>
            </div> */}
                    </div>
                  </div>
                </div>
                {/* ========================  Cart wrapper ======================== */}
                <div className="cart-wrapper">
                  {/*cart header */}
                  <div className="cart-block cart-block-header clearfix">
                    {/* <div>
                <span>Product</span>
              </div>
              <div>
                <span>&nbsp;</span>
              </div>
              <div>
                <span>Quantity</span>
              </div>
              <div className="text-right">
                <span>Price</span>
              </div> */}
                    <div>
                      <span>Product</span>
                    </div>

                    <div>
                      <span>Name</span>
                    </div>


                    <div className='text-left'>
                      <span>Color</span>
                    </div>

                    <div className="text">
                      <span>Quantity</span>
                    </div>
                    <div className="text-right">
                      <span>Price</span>
                    </div>
                  </div>
                  {/*cart items*/}
                  <div className="clearfix">
                    {cartItems.length > 0 ? (
                      cartItems.map((item) => (
                        <div className="cart-block cart-block-item clearfix" key={item.Id}>
                          <div className="image">
                            <Link to={`/product/${item.Id}/${item.Slug}`}> <center><img src={`${IMG_BASE_URL}${item.attimage}`} alt="pic" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px' }} /></center></Link>
                          </div>
                          <div className="title">
                            <div className="h6">{item.Name} ({item.ArticleNo})</div>
                            <div>{item.scName}</div>
                          </div>
                          <div className="title">
                            <div className="h6">{item.color}</div>
                          </div>
                          <div className="quantity">
                            <strong>{item.quantity}</strong>
                          </div>
                          <div className="price">
                            <span className="final h6">Rs.  {(parseFloat(item.SalePrice) * item.quantity).toFixed(2)}</span>
                            {/* <span className="discount">Rs. 2.666</span> */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <>

                        {back && back()} {/* Call the back function if it's defined */}

                        <div className='cart-block cart-block-item clearfix'>
                          <h2 align="center">Empty Cart</h2>
                        </div>
                      </>

                    )}


                  </div>
                  {/*cart prices */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        <strong>Discount</strong>
                      </div>
                      <div>
                        <span>Rs. {discount}</span>
                      </div>
                    </div>

                  </div>
                  <div className="clearfix">
                  <div className="cart-block cart-block-footer clearfix">
                    <div>
                      <strong>Shipping Charges</strong>
                    </div>
                    <div>
                      <span>Rs. {show ? (storeSc) : (<>...</ >)}</span>
                    </div>
                  </div>
                </div>
                  {/*cart final price */}
                  <div className="clearfix">
                    <div className="cart-block cart-block-footer clearfix">
                      <div>
                        {promoCode && (
                          <strong style={{ color: 'green' }}>Promo code included!</strong>
                        )}


                      </div>

                      <div>
                        <div className="h2 title">Rs. {finalAmount && (finalAmount.toFixed(2))}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========================  Cart navigation ======================== */}
                <div className="clearfix">
                  <div className="row">
                    <div className="col-xs-6">
                      <Link to="/checkout/information" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to Information</Link>
                      <Link to="/shop" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Continue Shopping</Link>

                    </div>
                    <div className="col-xs-6 text-right">
                      <button onClick={completeOrder} className="btn btn-main"><span className="icon icon-cart" /> Checkout</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
              <div style={{ marginTop: '12%'}}>
                <header className="hidden">
                  <h3 className="h3 title">Checkout - Step 1</h3>
                </header>
                <div className="clearfix">
                <div className="row">
                  <div className="col-xs-6">
                    {/* <Link to="/cartItems" className="btn btn-clean-dark"><span className="icon icon-chevron-left" /> Back to cart</Link> */}
                  </div>
                  <div className="col-xs-6 text-right">
                    {/* <button onClick={handleCreateAccount} className="btn btn-main"><span className="icon icon-cart" /> Go to payment</button> */}
                  </div>
                </div>
              </div>
                {/* ========================  Cart wrapper ======================== */}
                <div className="cart-wrapper">
                  {/*cart header */}
                  <div className="cart-block cart-block-header clearfix">
                    <div style={{ width: '100%', textAlign: 'center' }}>
                      <span>Customer Service</span>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className='cart-block cart-block-item clearfix' >
                     
                      <ul style={{ marginLeft: '4%', marginRight: '4%', marginTop: '10%' }}>
                        <li><span className="icon icon-phone-handset" /> +92-333-0348378</li>
                        <li><span className="icon icon-phone-handset" /> +92-55-111-936-936</li>
                        <li><span className="icon icon-envelope" /> shop@citizen.com.pk</li>
                        <hr />
                        <p>Sun-Thu and Sat: 09:00 AM – 05:00 PM <br /> Friday: Closed</p>
                      </ul>
                     
                      
                    </div>
                  </div>

                </div></div>
            </div>
            </div>

          </div> {/*/container*/}
        </section>
      </div>


    </>
  )
}
