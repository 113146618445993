import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link, useParams } from 'react-router-dom'
import Products from '../FetchApi/Products';
import ManageCart from '../Controllers/ManageCart';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';
import { setAppElement } from 'react-modal';
export default function ProductPage() {
  const params = useParams();
  const id = params.id;
  const [name, setName] = useState();
  const [thumbNail, setThumbNail] = useState();
  const [description, setDescription] = useState();
  const [stockQty, setStockQty] = useState();
  const [inStock, setInStock] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [sku, setSku] = useState(null);
  const [isOnline, setIsOnline] = useState(true);
  const [seoTitle, setSeoTitle] = useState();
  const [seoDescription, setSeoDescription] = useState();
  const [brandName, setBrandName] = useState();
  const { loadProductDetails, productDetail } = Products();
  const { handleOnChangeValue, addToCart } = ManageCart();

  const renderedImages = [];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show loader for 3 seconds (3000ms)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the time as needed

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    // Function to fetch product details and reset states
    const fetchProductDetails = async (productId) => {
      await loadProductDetails(productId);
    };

    // Reset states
    setName('');
    setThumbNail('');
    setDescription('');
    setStockQty(0);
    setSalePrice(null);
    setSku('');
    setBrandName('');
    setSeoTitle('');
    setSeoDescription('');
    setInStock(null);

    // Fetch new product details
    fetchProductDetails(id);
  }, [id]); // Dependency on 'id' to refetch on ID change

  if (productDetail) {

    for (let i = 0; i < productDetail.length; i++) {
      renderedImages.push(
        <a href={`${IMG_BASE_URL}${productDetail[i].attimage}`} key={i}>
          <center>
            <img
              src={`${IMG_BASE_URL}${productDetail[i].attimage}`}
              alt={`Product ${i + 2}`}
              style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '500px' }}
              height={500}
            />
          </center>

        </a>
      );
    }
  }


  const [color, setColor] = useState(null);
  const handleClick = (attributeValue) => {
    setColor(attributeValue);
  };
  useEffect(() => {
    loadProductDetails(id);
  }, [id]);

  useEffect(() => {
    if (productDetail) {
      setName(productDetail[0].Name);
      setThumbNail(productDetail[0].Thumbnai);
      setDescription(productDetail[0].LongDescription);
      setStockQty(productDetail[0].StockQty);
      setSalePrice(productDetail[0].SalePrice);
      setSku(productDetail[0].ArticleNo);
      setBrandName(productDetail[0].bname);
      setSeoTitle(productDetail[0].SEOTitle);
      setSeoDescription(productDetail[0].SeoDescription);
      if (productDetail[0].StockQty > 0) {
        setInStock(true);
      } else {
        setInStock(false);
      }
    } else {
      console.log("Product details is still undefined or loading");
    }
  }, [productDetail]);
  return (
    <>
    {loading &&
    <>
    <div className="page-loader" id="preloader"></div>
    </>}    
      {thumbNail ? <>
        <LoadScripts />
        <Helmet>
          <title>{seoTitle ? `${seoTitle} | Shop` : 'Loading...'}</title>
          <meta name="description" content={seoDescription ? seoDescription : 'Loading...'} />
          <meta property="og:title" content={seoTitle ? seoTitle : 'Loading...'} />
          <meta property="og:description" content={seoDescription ? seoDescription : 'Loading...'} />
        </Helmet>
        <div>
          <section className="main-header" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
            <header>
              <div className="container">
                <h1 className="h2 title">{name ? <>{name}</> : <>Loading...</>}</h1>
                <ol className="breadcrumb breadcrumb-inverted">
                  <li><Link to="/"><span className="icon icon-home" /></Link></li>
                  <li><Link to="/shop">Shop</Link></li>
                  <li>{name ? <>{name}</> : <>Loading...</>}</li>
                </ol>
              </div>
            </header>
          </section>
          <section className="product">
            <div className="main">
              <div className="container">
                <div className="row product-flex">
                  <div className="col-md-8 col-sm-12 product-flex-gallery">

                    <div className="owl-product-gallery open-popup-gallery">
                      <center><img src={`${IMG_BASE_URL}${thumbNail}`} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '500px' }} /></center>
                      {productDetail ? renderedImages : <>Loading...</>}

                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 product-flex-info">
                    <div className="clearfix">
                      <h1 className="title" data-title="CITIZEN">{name ? <>{name}</> : <>Loading...</>} <small>Article No :{sku ? <>{sku}</> : <>Loading...</>}</small></h1>
                      <div className="clearfix">
                        <div className="price">
                          <span className="h3">
                            Rs {salePrice ? <>{salePrice}</> : <>Loading...</>}
                          </span>
                        </div>
                        <hr />
                        <div className="info-box">
                          <span><strong>Manufacturer</strong></span>
                          <span>{brandName ? <>{brandName}</> : <>Loading...</>}</span>
                        </div>
                        <div className="info-box">
                          <span><strong>Availability</strong></span>
                          {inStock != null ? <>
                            {inStock == true ?
                              <span><i className="fa fa-check-square-o" /> In stock</span>
                              :
                              <span><i className="fa fa-truck" /> Out of stock</span>}
                          </> : <>Loading...</>}

                        </div>

                        <hr />

                        <div className="info-box">
                          <span><strong>Available Colors</strong></span>
                          <div className="product-colors clearfix">
                            {productDetail ? (<>
                              {productDetail.map((productDetail, index) => (
                                <React.Fragment key={index}>

                                  {productDetail.AttributeValue && <>
                                    <span
                                      className={`color-btn color-btn-${productDetail.AttributeValue.toLowerCase()}`}
                                      title={productDetail.AttributeValue}
                                      onClick={() => handleClick(productDetail.AttributeValue)}
                                    />
                                  </>
                                  }
                                </React.Fragment>

                              ))}
                            </>) : <><p>Loading</p></>}

                          </div>
                        </div>
                        <hr />
                        <div className="info-box">
                          {isOnline ? <>
                            <div className="row">
                              <div className='col-md-4 col-sm-12 product-flex-info'>
                                <Link to={color ? '/cartItems' : '#'}><button type="button" className="btn btn-success" onClick={() => {
                                  if (!color) {
                                    alert('Please select a color before adding to the cart.');
                                    return
                                  }
                                  const selectedProduct = productDetail.find(
                                    (product) => product.AttributeValue.toLowerCase() === color.toLowerCase()
                                  );
                                  if (selectedProduct) {
                                    addToCart(selectedProduct, color);
                                  } else {
                                    addToCart(productDetail[0], color);
                                  }
                                }}>Add To Cart</button>
                                </Link>
                              </div>
                              <div className="col-md-4 col-sm-12 product-flex-info">
                                <div className="quantity"><input type="number" className="form-control form-quantity" min="1" onInput={(event) => {
                                  const value = event.target.value;
                                  event.target.value = value.replace(/[^0-9]/g, '');
                                }}
                                  defaultValue={1} onChange={(event) => handleOnChangeValue(event, productDetail[0])} /></div>

                              </div>
                            </div>
                          </> : <button type="button" className="btn btn-primary">Get a Qoute</button>
                          }
                        </div>

                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
            <div className="info">
              <div className="container">
                <div className="row">

                  <div className="col-md-12">
                    <ul className="nav nav-tabs" role="tablist">

                      <li role="presentation">
                        <a href="#design" aria-controls="design" role="tab" data-toggle="tab">
                          <i className="icon icon-sort-alpha-asc" />
                          <span>Specification</span>
                        </a>
                      </li>

                    </ul>
                    <div className="tab-content">

                      <div role="tabpanel" className="tab-pane active" id="design">
                        <div className="content">
                          <div className="row">

                            <div className="col-md-12">
                              <h3>Product Specification</h3>
                              <p>
                                {description ? <>{description}</> : <>Loading...</>}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </> : <>
        <section className="main-header" style={{ backgroundImage: 'url(/assets/images/breadcrumb.jpg)' }}>
          <header>
            <div className="container">
              <h1 className="h2 title">{name ? <>{name}</> : <>Loading...</>}</h1>
              <ol className="breadcrumb breadcrumb-inverted">
                <li><Link to="/"><span className="icon icon-home" /></Link></li>
                <li><Link to="/shop">Shop</Link></li>
                <li>{name ? <>{name}</> : <>Loading...</>}</li>
              </ol>
            </div>
          </header>
        </section>
        <div className="loading-container">
        <section className="product">
          <div className="main">
            <div className="container">
              <div className="row product-flex">
                <div className="col-md-8 col-sm-12 product-flex-gallery">

                  <div className="owl-product-gallery open-popup-gallery">
                   
                  <div className="loading-image skeleton" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 product-flex-info">
                  <div className="clearfix">
                    <h1 className="title" data-title="CITIZEN"><div className="loading-title skeleton"></div> <small><div className="loading-title skeleton"></div></small></h1>
                    <div className="clearfix">
                      <div className="price">
                        <span className="h3">
                          <div className="loading-price skeleton"></div>
                        </span>
                      </div>
                      <hr />
                      <div className="info-box">
                        <span><strong>Manufacturer</strong></span>
                        <span><div className="loading-manufacturer skeleton"></div></span>
                      </div>
                      <div className="info-box">
                        <span><strong>Availability</strong></span>
                        <div className="loading-availability skeleton"></div>

                      </div>

                      <hr />

                      <div className="info-box">
                        <span><strong>Available Colors</strong></span>
                        <div className="product-colors clearfix">
                          <div className="loading-colors skeleton"></div>

                        </div>
                      </div>
                      <hr />
                      <div className="info-box">
                        {isOnline ? <>
                          <div className="row">
                            <div className='col-md-4 col-sm-12 product-flex-info'>
                              <div className="loading-colors skeleton"></div>
                            </div>
                            <div className="col-md-4 col-sm-12 product-flex-info">
                              <div className="loading-description skeleton"></div>
                            </div>
                          </div>
                        </> : <button type="button" className="btn btn-primary">Get a Qoute</button>
                        }
                      </div>

                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
          <div className="info">
            <div className="container">
              <div className="row">

                <div className="col-md-12">
                  <ul className="nav nav-tabs" role="tablist">

                    <li role="presentation">
                      <a href="#design" aria-controls="design" role="tab" data-toggle="tab">
                        <i className="icon icon-sort-alpha-asc" />
                        <span>Specification</span>
                      </a>
                    </li>

                  </ul>
                  <div className="tab-content">

                    <div role="tabpanel" className="tab-pane active" id="design">
                      <div className="content">
                        <div className="row">

                          <div className="col-md-12">
                            <h3>Product Specification</h3>
                            <p>
                              <div className="loading-description skeleton"></div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </>}
    </>
  )
}



