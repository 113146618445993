// import React, { useState, useEffect } from 'react'
// import LoadScripts from '../Components/LoadScripts'
// import { Helmet } from 'react-helmet';
// import City from '../FetchApi/City';

// export default function () {
//     const { fetchCities, cities, fetchCityDetail, citydetails } = City();
//     const [city, setCity] = useState();
//     const [ selectedCity, setSelectedCity] = useState("All");
//     const [filteredCities, setFilteredCities] = useState();

//     const changeSelectedCity = (id) => {
//         setSelectedCity(id); // Update the selected category in state
//         if (id) {
//           const newFilteredCities = citydetails.CityDetails.filter(CityDetails => CityDetails.CityName === id);
//           setFilteredCities(newFilteredCities);
          
//         } else {
//           // If no category is selected (id is null or undefined), show all products
//           setFilteredCities(citydetails.CityDetails);
//         }
//       };
//     useEffect(() => {
//         fetchCities();
//         fetchCityDetail();
        
//     }, []);
   
//     return (
//         <>
//             <Helmet>
//                 <title>Store Locator | Citizen Plastic</title>
//                 <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
//                 <meta property="og:title" content="About Us | Citizen Plastic" />
//                 <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
//             </Helmet>
//             <LoadScripts />
//             <div>
//                 <section className="main-header main-header-blog" style={{ backgroundImage: 'url(assets/images/breadcrumb.jpg)' }}>
//                     <header>
//                         <div className="container text-center">
//                             <h2 className="h2 title">Store Locator</h2>
//                             <p className="subtitle"></p>
//                         </div>
//                     </header>
//                 </section>
//                 <section className="login-wrapper login-wrapper-page">
//                     <div className="container">
//                         <header>
//                             <div className="row">
//                                 <div className="col-md-offset-2 col-md-8 text-center">
//                                     <h2 className="title">Select the City to Locate Store.</h2>
//                                 </div>
//                             </div>
//                         </header>
            
//                         {cities.City && (
//                             <div className="row">
//                                 <div className="col-md-offset-1 col-md-10">
//                                     <div className="form-group">

//                                         <select
//                                             id="city-select"
//                                             className="form-control"
//                                             value={city}
//                                             onChange={(e) => changeSelectedCity(e.target.value)}
//                                         >
//                                             <option value="All">All</option>
//                                             {cities.City.map((city, index) => (
//                                                 <option key={index} value={city.Name}>
//                                                     {city.Name}
//                                                 </option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     {/* <div className="row">
//                         <div className="col-md-offset-2 col-md-8">
//                             <div class="panel-body">
//                                 <table class="table table-hover">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>First Name</th>
//                                             <th>Tables</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr>
//                                             <td>1</td>
//                                             <td>Michael</td>
//                                             <td>Are formatted like this</td>
//                                         </tr>
//                                         <tr>
//                                             <td>2</td>
//                                             <td>Lucille</td>
//                                             <td>Do you like them?</td>
//                                         </tr>
//                                         <tr class="success">
//                                             <td>3</td>
//                                             <td>Success</td>
//                                             <td></td>
//                                         </tr>
//                                         <tr class="danger">
//                                             <td>4</td>
//                                             <td>Danger</td>
//                                             <td></td>
//                                         </tr>
//                                         <tr class="warning">
//                                             <td>5</td>
//                                             <td>Warning</td>
//                                             <td></td>
//                                         </tr>
//                                         <tr class="active">
//                                             <td>6</td>
//                                             <td>Active</td>
//                                             <td></td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                                 <table class="table table-striped table-bordered table-condensed">
//                                     <thead>
//                                         <tr>
//                                             <th>#</th>
//                                             <th>First Name</th>
//                                             <th>Tables</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr>
//                                             <td>1</td>
//                                             <td>Michael</td>
//                                             <td>This one is bordered and condensed</td>
//                                         </tr>
//                                         <tr>
//                                             <td>2</td>
//                                             <td>Lucille</td>
//                                             <td>Do you still like it?</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>

//                             </div>
//                         </div>
//                     </div> */}
                 
//                         <div className="row">
//                         <div className="col-md-offset-1 col-md-10">
//                             <div class="panel panel-default" id="tables">
//                                 <div class="panel-heading">Stores</div>
//                                 <div class="panel-body">
//                                 {citydetails.CityDetails && <>
//                                     <table class="table table-hover">
//                                         <thead>
//                                             <tr>
//                                                 <th>#</th>
//                                                 <th>City Name</th>
//                                                 <th>Store Name</th>
//                                                 <th>Contact Person</th>
//                                                 <th>Contact Phone</th>
//                                                 <th>Address</th>
//                                                 <th>Map</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {citydetails.CityDetails.map((citydetails, index) => (
//                                                  <tr key={index}>
//                                                  <td>{index + 1}</td>
//                                                  <td>{citydetails.CityName}</td>
//                                                  <td>{citydetails.StoreName}</td>
//                                                  <td>{citydetails.ContactPerson}</td>
//                                                  <td>{citydetails.Phone}</td>
//                                                  <td>{citydetails.Address}</td>
//                                                  <td><a href={citydetails.MapLink} type='button' className='btn btn-info' target='_blank'>Google Maps</a></td>
//                                              </tr>
//                                             ))}
                                           
//                                         </tbody>
//                                     </table>
//                                     </>}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
                    
                  

//                 </section>
//             </div>
//         </>
//     )
// }
import React, { useState, useEffect } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';
import City from '../FetchApi/City';

export default function () {
    const { fetchCities, cities, fetchCityDetail, citydetails } = City();
    const [city, setCity] = useState("All"); // Set default to "All"
    const [filteredCities, setFilteredCities] = useState([]); // Hold filtered city details

    // Function to filter city details based on selected city
    const changeSelectedCity = (selectedCity) => {
        setCity(selectedCity); // Update the selected city in state
        if (selectedCity === "All") {
            setFilteredCities(citydetails.CityDetails); // Show all cities if "All" is selected
        } else {
            const newFilteredCities = citydetails.CityDetails.filter(cityDetail => cityDetail.CityName === selectedCity);
            setFilteredCities(newFilteredCities);
        }
    };

    useEffect(() => {
        fetchCities();
        fetchCityDetail();
    }, []);

    useEffect(() => {
        // By default, show all city details when they are first fetched
        if (citydetails && citydetails.CityDetails) {
            setFilteredCities(citydetails.CityDetails);
        }
    }, [citydetails]); // Re-run this effect when citydetails is updated

    return (
        <>
            <Helmet>
                <title>Store Locator | Citizen Plastic</title>
                <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
                <meta property="og:title" content="About Us | Citizen Plastic" />
                <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
            </Helmet>
            <LoadScripts />
            <div>
                <section className="main-header main-header-blog" style={{ backgroundImage: 'url(assets/images/breadcrumb.jpg)' }}>
                    <header>
                        <div className="container text-center">
                            <h2 className="h2 title">Store Locator</h2>
                            <p className="subtitle"></p>
                        </div>
                    </header>
                </section>
                <section className="login-wrapper login-wrapper-page">
                    <div className="container">
                        <header>
                            <div className="row">
                                <div className="col-md-offset-2 col-md-8 text-center">
                                    <h2 className="title">Select the City to Locate Store.</h2>
                                </div>
                            </div>
                        </header>

                        {cities.City && (
                            <div className="row">
                                <div className="col-md-offset-1 col-md-10">
                                    <div className="form-group">
                                        <select
                                            id="city-select"
                                            className="form-control"
                                            value={city} // Controlled input based on city state
                                            onChange={(e) => changeSelectedCity(e.target.value)} // Update selected city
                                        >
                                            <option value="All">All</option>
                                            {cities.City.map((cityOption, index) => (
                                                <option key={index} value={cityOption.Name}>
                                                    {cityOption.Name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-default" id="tables">
                                    <div className="panel-heading">Stores</div>
                                    <div className="panel-body">
                                        {filteredCities.length > 0 ? (
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>City</th>
                                                        <th>Store</th>
                                                        <th>Person</th>
                                                        <th>Phone</th>
                                                        <th>Address</th>
                                                        <th>Map</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredCities.map((cityDetail, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{cityDetail.CityName}</td>
                                                            <td>{cityDetail.StoreName}</td>
                                                            <td>{cityDetail.ContactPerson}</td>
                                                            <td>{cityDetail.Phone}</td>
                                                            <td>{cityDetail.Address}</td>
                                                            <td><a href={cityDetail.MapLink} type='button' className='btn btn-info' target='_blank'>Map</a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <p>No stores available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
