import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function NotFound() {
  return (
   <>
     <Helmet>
        <title>Not Found| Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Not Found | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
    </Helmet>
         <LoadScripts />
      <section className="not-found">
        <div className="container">
          <h1 className="title" data-title="Page not found!">404</h1>
          <div className="h4 subtitle">Sorry! Page not found.</div>
          <p>The requested URL was not found on this server. That’s all we know.</p>
          <p>Click <a href="#">here</a> to get to the front page? </p>
        </div>
      </section>

   </>
  )
}
