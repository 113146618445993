import React from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Helmet } from 'react-helmet';


export default function () {
  return (
    <>
    <Helmet>
      <title>Privacy Policy | Citizen Plastic</title>
      <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      <meta property="og:title" content="Privacy Policy | Citizen Plastic" />
      <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
  <LoadScripts />
  <div>
<section className="main-header main-header-blog" style={{backgroundImage: 'url(assets/images/breadcrumb.jpg)'}}>
  <header>
    <div className="container text-center">
      <h2 className="h2 title">Privacy Policy</h2>
      {/* <p className="subtitle">Track your Order</p> */}
    </div>
  </header>
</section>
<section className="login-wrapper login-wrapper-page">
    <div className="container">
    {/* <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Track Your Order by Entrying the Order Now</h2>
              </div>
            </div>
          </header> */}
          <div className="row">
            <div className="col-md-12">
                <p style={{ fontSize: '25px'}}>Citizen.com.pk is committed to keeping the online shopping experience safe and secured for every user. Therefore, we have made the rigid and reliable privacy policy.
                </p>
            <ol style={{ fontSize: '25px' }}>
                
            <li>	During registration, we ask you for your personal details, such as name, address, contact details, email etc. We ensure the encryption of your personal information.</li>
            <li>	To preserve the safety rights of community, the user should use their actual identity for creating the account.</li>
            <li>	The user must be aged 18 or above. In other case, user, aged below 18, can use the account of their guardian or parent, with their permission.</li>
            <li>	For frequent communication (if needed), it is recommended to provide correct contact details.</li>
            <li>	Customer should check his/her email or phone number is valid and working correctly, so our department can reach customers timely.</li>
            </ol>
            </div>
          </div>
    </div>
  </section>
</div>
</>
  )
}
