import React, { useState } from 'react';
import LoadScripts from '../Components/LoadScripts';
import { Helmet } from 'react-helmet';
import GetInvoice from '../FetchApi/GetInvoice';

export default function TrackOrder() {
  const [searchOption, setSearchOption] = useState('orderId'); // Default is search by Order ID
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [formError, setFormError] = useState(true);
  const { trackOrder, trackOrderByEmailPhone, order } = GetInvoice();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchOption === 'orderId') {
      trackOrder(orderId); // Fetch by Order ID
    } else {

      setFormError(false);

      // Check if search option is 'emailPhone' and validate the fields
      if (searchOption === 'email') {
        if (!email) {
          setFormError(true);
          return;
        }
        trackOrderByEmailPhone(email, ''); // Fetch by Email and Phone
      }
      if (searchOption === 'phone') {
        if (!phone) {
          setFormError(true);
          return;
        }
        trackOrderByEmailPhone('', phone); // Fetch by Email and Phone
      }

      
    }
  };

  return (
    <>
      <Helmet>
        <title>Track Order | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Track Order | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
      </Helmet>
      <LoadScripts />
      <div>
        <section className="main-header main-header-blog" style={{ backgroundImage: 'url(assets/images/breadcrumb.jpg)' }}>
          <header>
            <div className="container text-center">
              <h2 className="h2 title">Track Order</h2>
              <p className="subtitle">Track your Order</p>
            </div>
          </header>
        </section>
        <section className="login-wrapper login-wrapper-page">
          <div className="container">
            <header>
              <div className="row">
                <div className="col-md-offset-2 col-md-8 text-center">
                  <h2 className="title">Track Your Order by Entering the Information</h2>
                  <h5 style={{ textAlign: 'left' }}>You can track your order using either the Order ID or by providing your Email or Phone number.</h5>
                </div>
              </div>
            </header>
            <div className="row">
  <div className="col-md-offset-2 col-md-8">
    <form onSubmit={handleSubmit}>
      {/* Radio buttons to select search method */}
      <div className="form-group">
        <label>Track Order By:</label><br />
        <div className="btn-group" role="group" aria-label="Search Option">
          <button
            type="button"
            className={`btn ${searchOption === 'orderId' ? 'btn-primary' : 'btn'}`}
            onClick={() => setSearchOption('orderId')}
          >
            Order ID
          </button>
          <button
            type="button"
            className={`btn ${searchOption === 'email' ? 'btn-primary' : 'btn'}`}
            onClick={() => setSearchOption('email')}
            style={{ marginLeft: '10px' }}
          >
            Email
          </button>
          <button
            type="button"
            className={`btn ${searchOption === 'phone' ? 'btn-primary' : 'btn'}`}
            onClick={() => setSearchOption('phone')}
            style={{ marginLeft: '10px' }}
          >
            Phone
          </button>
        </div>
      </div>

      {/* Conditional Inputs based on search option */}
      {searchOption === 'orderId' ? (
        <div className="form-group">
          <label>Order ID</label>
          <input
            type="text"
            className="form-control"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            placeholder="Enter your order ID"
            required
          />
        </div>
      ) : searchOption === 'email' ? (
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
      ) : (
        <div className="form-group">
          <label>Phone</label>
          <input
            type="tel"
            className="form-control"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your phone number"
            required
          />
        </div>
      )}

      <div className="form-group">
        <button type="submit" className="btn btn-main">Track Order</button>
      </div>
    </form>
  </div>
</div>



            {/* Displaying the order information based on the fetched data */}
            {/* {order && (
              order.Order && order.Status === 200 && order.OrderStausId !== 0 ? (
                <div className="row">
                  <div className="cart-wrapper">
                    <div className="note-block">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="white-block">
                            <div className="h4">Order Info</div>
                            <hr />

                            {
                              (order.Order.Name === 'Pending' ||
                                order.Order.Name === 'Progress' ||
                                order.Order.Name === 'Delivered' ||
                                order.Order.Name === 'Completed') ? (
                                <>
                                  <div className="step-wrapper">
                                    <div className="stepper">
                                      <ul className="row">
                                        <li className={`col-md-3 ${order.Order.Name === 'Pending' || order.Order.Name === 'Progress' || order.Order.Name === 'Delivered' || order.Order.Name === 'Completed' ? 'active' : ''}`}>
                                          <span data-text="Pending" />
                                        </li>
                                        <li className={`col-md-3 ${order.Order.Name === 'Progress' || order.Order.Name === 'Delivered' || order.Order.Name === 'Completed' ? 'active' : ''}`}>
                                          <span data-text="Progress" />
                                        </li>
                                        <li className={`col-md-3 ${order.Order.Name === 'Delivered' || order.Order.Name === 'Completed' ? 'active' : ''}`}>
                                          <span data-text="Delivered" />
                                        </li>
                                        <li className={`col-md-3 ${order.Order.Name === 'Completed' ? 'active' : ''}`}>
                                          <span data-text="Completed" />
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="alert alert-warning" role="alert">
                                    {order.Order.Name}
                                  </div>
                                </>
                              )
                            }


                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <strong>Order No</strong> <br />
                                  <span>{order.Order.OrderNo}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <strong>Net Total</strong><br />
                                  <span>{order.Order.NetTotal}</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <strong>Quantity</strong><br />
                                  <span>{order.Order.Quantity}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger" role="alert">
                  No Order Found
                </div>
              )
            )} */}
          {order && (
  order.Status === 200 && order.Order ? (
    <div className="row">
      <div className="cart-wrapper">
        <div className="note-block">
          <hr />

          {/* Handle case for both single order (object) and multiple orders (array) */}
          {Array.isArray(order.Order) ? (
            order.Order.length > 0 ? ( // Check if array has orders
              // Loop through the orders if it's an array
              order.Order.map((singleOrder, index) => (
                <div className="row" key={index}>
                  <div className="col-md-12">
                    <div className="white-block">
                      <div className="h4">Order Info</div>
                      <h5>Order {index + 1}</h5>
                      {renderOrderDetails(singleOrder)}
                      <br />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="alert alert-warning" role="alert">
                No orders found.
              </div>
            )
          ) : (
            // Handle single order (object)
            <div className="row">
              <div className="col-md-12">
                <div className="white-block">
                  <div className="h4">Order Info</div>
                  <div>
                    {renderOrderDetails(order.Order)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="alert alert-danger" role="alert">
      No Order Found
    </div>
  )
)}


          </div>
        </section>
      </div>
    </>
  );
}

const renderOrderDetails = (singleOrder) => (
  <>
    {(singleOrder.Name === 'Pending' ||
      singleOrder.Name === 'Progress' ||
      singleOrder.Name === 'Delivered' ||
      singleOrder.Name === 'Completed') ? (
      <>
        <div className="step-wrapper">
          <div className="stepper">
            <ul className="row">
              <li className={`col-md-3 ${['Pending', 'Progress', 'Delivered', 'Completed'].includes(singleOrder.Name) ? 'active' : ''}`}>
                <span data-text="Pending" />
              </li>
              <li className={`col-md-3 ${['Progress', 'Delivered', 'Completed'].includes(singleOrder.Name) ? 'active' : ''}`}>
                <span data-text="Progress" />
              </li>
              <li className={`col-md-3 ${['Delivered', 'Completed'].includes(singleOrder.Name) ? 'active' : ''}`}>
                <span data-text="Delivered" />
              </li>
              <li className={`col-md-3 ${singleOrder.Name === 'Completed' ? 'active' : ''}`}>
                <span data-text="Completed" />
              </li>
            </ul>
          </div>
        </div>
      </>
    ) : (
      <div className="alert alert-warning" role="alert">
        {singleOrder.Name}
      </div>
    )}

    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <strong>Order No</strong> <br />
          <span>{singleOrder.OrderNo}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <strong>Net Total</strong><br />
          <span>{singleOrder.NetTotal}</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <strong>Quantity</strong><br />
          <span>{singleOrder.Quantity}</span>
        </div>
      </div>
    </div>
  </>
);