import React, { useEffect, useState } from 'react'
import LoadScripts from '../Components/LoadScripts'
import { Link } from 'react-router-dom'
import Category from '../FetchApi/Category';
import Products from '../FetchApi/Products';
import { IMG_BASE_URL } from '../config';
import { Helmet } from 'react-helmet';


export default function Home() {
  const { loadCategory, categories } = Category();
  const { loadProducts, products } = Products();
  const  [visibleCount, setVisibleCount ] = useState(4); // Initially show 3 categories


  const showMoreCategories = () => {
    setVisibleCount(prevCount => prevCount + 4); // Load 3 more categories on each click
  };

  useEffect(() => {

    loadCategory();
    loadProducts();
  }, []); // Call the loadCategory function when the component mounts

  const hasCategories = Array.isArray(categories.categories);
  const hasProducts = Array.isArray(products.products);
  return (
    <>
       <Helmet>
        <title>Citizen Plastic Furniture | Citizen Plastic</title>
        <meta name="description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        <meta property="og:title" content="Citizen Plastic Furniture | Citizen Plastic" />
        <meta property="og:description" content="Pakistan No.1 Plastic Furniture Selling Brand" />
        </Helmet>
    
      <LoadScripts />
      <section className="header-content">
        <div className="owl-slider">
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-1.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">Mesh Back <br />Revolving Chair</h2>
                <div className="animated" data-animation="fadeInUp">
                A modern black revolving chair with a breathable <br /> mesh backrest, cushioned seat, armrests, and a five-wheel <br />base for easy mobility. Ideal for office or home workspaces.
                
                </div>
                {/* <div className="animated" data-animation="fadeInUp">
                    <a href="https://themeforest.net/item/mobel-furniture-website-template/20382155" target="_blank"  rel="noreferrer" className="btn btn-main"><i className="icon icon-cart" /> Buy this template</a>
                  </div> */}
              </div>
            </div>
          </div>
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-2.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">Study Collection</h2>
                <div className="animated" data-animation="fadeInUp">Comfortable seats designed for long periods of </div>
                <div className="animated" data-animation="fadeInUp">sitting while studying or working</div>
                {/* <div className="animated" data-animation="fadeInUp">
                  <a href="category.html" className="btn btn-clean">Get insipred</a>
                </div> */}
              </div>
            </div>
          </div>
          {/* === slide item === */}
          <div className="item" style={{ backgroundImage: 'url(assets/images/gallery-3.jpg)' }}>
            <div className="box">
              <div className="container">
                <h2 className="title animated h1" data-animation="fadeInDown">
                  Plastic Furniture
                </h2>
                <div className="desc animated" data-animation="fadeInUp">
                Lightweight, durable, and versatile seating options made from molded plastic. 
                </div>
                <div className="desc animated" data-animation="fadeInUp">
                They are commonly used in indoor and outdoor settings.
                </div>
              </div>
            </div>
          </div>
        </div> {/*/owl-slider*/}
      </section>
    
      {/* ========================  Products widget ======================== */}



      <section className="products">
        <div style={{ marginLeft: '6%', marginRight: '6%'}}>
          {/* === header title === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="title">Popular products</h2>
                <div className="text">
                  <p>Check out our latest collections</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {hasProducts && products.products.length > 0 ? (
              products.products.slice(0, 12).map((product, index) => (
                <React.Fragment key={product.Id}> 
                {/* <div className="col-md-3 col-xs-6 col-sm-4">
                  <article>
                    <div className="info">
                    
                      <span>
                      <Link to={`product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link>
                      </span>
                    </div>
                    <Link to={`product/${product.Id}/${product.Slug}`}>
                    <div className="btn btn-add">
                      <i className="icon icon-cart" />
                    </div>
                    </Link>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="figure-grid"  style={{ marginTop: '10%'}}>
                      <div className="image">
                        {product.Thumbnai ? <>
                          <Link to={`product/${product.Id}/${product.Slug}`}>
                          
                          <center><img src={`${IMG_BASE_URL}${product.Thumbnai}`} style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px', transition: 'transform 0.3s ease' }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'} /></center>

                        </Link>
                        </> : <>
                        <Link to={`product/${product.Id}/${product.Slug}`}>
                          <center><img src="/assets/images/logo1.png"  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center>
                        </Link>
                        </>}
                      
                      </div>
                      <br />
                      <br />
                      <div className="text">
                        <h2 className="title h3"><Link to={`product/${product.Id}/${product.Slug}`}>{product.Name}</Link></h2>
                        <h2 className="title h4"><Link to={`product/${product.Id}/${product.Slug}`}>({product.ArticleNo})</Link></h2>
                        <sup><h6>Rs {product.SalePrice}</h6></sup>
                        <span className="description clearfix">{product.LongDescription}</span>
                        
                      </div>
                    </div>
                  </article>
                </div> */}
                  <div className="col-md-2 col-xs-6 col-sm-4">
                    <article>
                      <div className="info">
                        {/* <span className="add-favorite added">
        <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
      </span> */}
                        <span>
                          <Link to={`product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link>
                        </span>
                      </div>
                      <Link to={`product/${product.Id}/${product.Slug}`}>
                        <div className="btn btn-add">
                          <i className="icon icon-cart" />
                        </div>
                      </Link>
                      <div className="figure-grid" style={{ marginTop: '10%' }}>
                        <div className="image">
                          {product.Thumbnai ? (
                            <Link to={`product/${product.Id}/${product.Slug}`}>
                              <center>
                                <img
                                  src={`${IMG_BASE_URL}${product.Thumbnai}`}
                                  style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '200px', transition: 'transform 0.3s ease' }}
                                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.2)'}
                                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                />
                              </center>
                            </Link>
                          ) : (
                            <Link to={`product/${product.Id}/${product.Slug}`}>
                              <center>
                                <img src="/assets/images/logo1.png" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px' }} />
                              </center>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="text" style={{ marginLeft: '5%' ,marginRight: '5%'}}>
                        <h2 className="title" style={{ fontSize: '12px'}}><Link to={`product/${product.Id}/${product.Slug}`}>{product.Name}</Link></h2>
                        <h2 className="title" style={{ fontSize: '12px'}}><Link to={`product/${product.Id}/${product.Slug}`}>({product.ArticleNo})</Link></h2>
                        <sup><h6>Rs {product.SalePrice}</h6></sup>
                        {/* <span className="description clearfix">{product.LongDescription}</span> */}
                      </div>
                    </article>
                  </div>

                 </React.Fragment>
              ))
            ) : (<>
              {Array.from({ length: 12,}, (_, index) =>(
              <div className="col-md-2 col-xs-6 col-sm-4">
              <article>
                <div className="info">
                  {/* <span className="add-favorite added">
                    <a href="#" onClick={(e) => e.preventDefault()} data-title="Add to favorites" data-title-added="Added to favorites list"><i className="icon icon-heart" /></a>
                  </span> */}
                  <span>
                  {/* <Link to={`product/${product.Id}/${product.Slug}`}><i className="icon icon-eye" /></Link> */}
                  </span>
                </div>
                {/* <Link to={`product/${product.Id}/${product.Slug}`}> */}
                <div className="btn btn-add">
                  <i className="icon icon-cart" />
                </div>
                {/* </Link> */}
                <br />
                <br />
                <br />
                <br />
                <div className="figure-grid"  style={{ marginTop: '10%'}}>
                  <div className="image">
                  <div className="loading-image1 skeleton"  />
                    {/* <Link to={`product/${product.Id}/${product.Slug}`}>
                      <center><img src={`${IMG_BASE_URL}${encodeURIComponent(product.Thumbnai)}`}  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center>
                    </Link> */}
                  </div>
                  <br />
                  <br />
                  <div className="text">
                    <h2 className="title h3"><div className="loading-title skeleton" /></h2>
                    <h2 className="title h4"><div className="loading-title2 skeleton" /></h2>
                    {/* <sub>Rs {product.SalePrice}</sub> */}
                    <div className="loading-title3 skeleton" />
                    <span className="description clearfix"><div className="loading-title skeleton" /></span>
                    
                  </div>
                </div>
              </article>
            </div>
              ))}
             
             </>

            )}
            
          </div> {/*/row*/}
          {/* === button more === */}
          <div className="wrapper-more">
            <Link to="/shop" className="btn btn-main">View store</Link>
          </div>
          {/* ========================  Product info popup - quick view ======================== */}
        </div> 
        {/*/container*/}
      </section>
      {/* ========================  Stretcher widget ======================== */}
      <section className="stretcher-wrapper">
        {/* === stretcher header === */}
        <header className="hidden">
          {/*remove class 'hidden'' to show section header */}
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Popular categories</h1>
                <div className="text">
                  <p>
                    Whether you are changing your home, or moving into a new one, you will find a huge selection of quality living room furniture,
                    bedroom furniture, dining room furniture and the best value at Furniture factory
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* === stretcher === */}
        <ul className="stretcher">
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-2.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-bedroom" />
                <span className="text-intro">Bedroom</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>FULL PLASTIC CHAIRS</h4>
              {/* <figcaption>New furnishing ideas</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-3.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-sofa" />
                <span className="text-intro">Living room</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>FULL PLASTIC CRYSTAL</h4>
              {/* <figcaption>Discover the design table collection</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-4.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-office" />
                <span className="text-intro">Office</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>FOLDING TABLES</h4>
              {/* <figcaption>Wardrobes vs Walk-In Closets</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item === */}
          <li className="stretcher-item" style={{ backgroundImage: 'url(assets/images/gallery-1.png)' }}>
            {/*logo-item*/}
            <div className="stretcher-logo">
              <div className="text">
                {/* <span className="f-icon f-icon-bathroom" />
                <span className="text-intro">Bathroom</span> */}
              </div>
            </div>
            {/*main text*/}
            <figure>
              <h4>REVOLVING CHAIR</h4>
              {/* <figcaption>Creating Your Very Own Bathroom</figcaption> */}
            </figure>
            {/*anchor*/}
            <Link to="/shop">Anchor link</Link>
          </li>
          {/* === stretcher item more=== */}
          <li className="stretcher-item more">
            <div className="more-icon">
              <span data-title-show="Show more" data-title-hide="+" />
            </div>
            <Link to="/shop">Anchor link</Link>
          </li>
        </ul>
      </section>
      {/* ========================  Blog Block ======================== */}
      {/* ========================  Blog ======================== */}
      <section className="blog">
        <div className='container'>
          {/* === blog header === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Categories</h1>
                <div className="text">
                  <p>Explore our Categories</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {/* === blog items generated from categories === */}
            {hasCategories && categories.categories.length > 0 ? (
              categories.categories.slice(0, visibleCount).map((category, index) => (
                <div className="col-sm-3" key={index}>
                  <article>
                    <Link to="/shop">
                       <div className="image" style={{ backgroundImage: `url(${IMG_BASE_URL}${category.Picture})` }} >
                        {/* <img src={`${IMG_BASE_URL}${category.Picture}`} alt={category.Name} /> */}
                        <center><img src={`${IMG_BASE_URL}${category.Picture}`}  style={{ width : 'auto', height: 'auto', maxWidth: '100%', maxHeight: '270px'}} /></center>
                      </div>
                      <div className="entry entry-table">
                        <div className="title">
                          <h2 className="h5">{category.Name}</h2>
                          <h2 className="h6">{category.Description}</h2>
                        </div>
                      </div>
                      <div className="show-more">
                        {/* <Link to="/shop"> */}
                        <span className="btn btn-main btn-block">Read more</span>
                        {/* </Link> */}
                      </div>
                    </Link>
                  </article>
                </div>
              ))
            ) : (
              <p>No categories available.</p>
            )}
          </div> {/*/row*/}
          
          {/* === button more === */}
          {hasCategories && visibleCount < categories.categories.length && (
            <div className="wrapper-more">
              <button onClick={showMoreCategories} className="btn btn-main">Show more</button>
            </div>
          )}
        </div> {/*/container*/}
      </section>
      {/* ========================  Banner ======================== */}
      <section className="banner" style={{ backgroundImage: 'url(assets/images/gallery-4.jpg)' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-offset-2 col-md-8 text-center">
              <h2 className="title">Our story</h2>
              <p>
                We believe in creativity as one of the major forces of progress. With this idea, we traveled throughout Italy to find exceptional
                artisans and bring their unique handcrafted objects to connoisseurs everywhere.
              </p>
              <p><Link to="about" className="btn btn-clean">Read full story</Link></p>
            </div>
          </div>
        </div>
      </section>
      {/* ========================  Blog ======================== */}
      <section className="blog">
        <div className="container">
          {/* === blog header === */}
          <header>
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h1 className="h2 title">Blog</h1>
                <div className="text">
                  <p>Latest news from the blog</p>
                </div>
              </div>
            </div>
          </header>
          <div className="row">
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <Link to="/blog/1">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-1.jpg)' }}>
                    <img src="assets/images/blog-1.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    {/* <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>08</strong>
                        <span>2017</span>
                      </div>
                    </div> */}
                    <div className="title">
                      <h2 className="h5">Why Citizen Plastic Furniture is the Best Choice for Modern Homes and Offices</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </Link>
              </article>
            </div>
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <Link to="/blog/2">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-2.jpg)' }}>
                    <img src="assets/images/blog-2.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    {/* <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>03</strong>
                        <span>2017</span>
                      </div>
                    </div> */}
                    <div className="title">
                      <h2 className="h5">Transform Your Space with Citizen Plastic Furniture: Durable, Stylish, and Affordable</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </Link>
              </article>
            </div>
            {/* === blog item === */}
            <div className="col-sm-4">
              <article>
                <Link to="/blog/3">
                  <div className="image" style={{ backgroundImage: 'url(assets/images/blog-3.jpg)' }}>
                    <img src="assets/images/blog-3.jpg"  />
                  </div>
                  <div className="entry entry-table">
                    {/* <div className="date-wrapper">
                      <div className="date">
                        <span>MAR</span>
                        <strong>01</strong>
                        <span>2017</span>
                      </div>
                    </div> */}
                    <div className="title">
                      <h2 className="h5">Durability and Style: Why Citizen Plastic Chairs Are a Must-Have for Every Event ...</h2>
                    </div>
                  </div>
                  <div className="show-more">
                    <span className="btn btn-main btn-block">Read more</span>
                  </div>
                </Link>
              </article>
            </div>
          </div> {/*/row*/}
          {/* === button more === */}
          {/* <div className="wrapper-more">
            <Link to="blog-grid.html" className="btn btn-main">View all posts</Link>
          </div> */}
        </div> {/*/container*/}
      </section>

      
      {/* ========================  Instagram ======================== */}
    
      <section className="instagram">
        
          {/* === header === */}
          <header>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="h2 title">Our Clients 
                  {/* <i className="fa fa-instagram fa-2x" /> Instagram  */}
                  </h2>
              
              </div>
            </div>
          </div>
        </header>
          <div className="container">
            <div className="owl-icons">
              {/* === icon item === */}
                      
                <figure>
                  <img src="/assets/images/1.png" alt="" />
                  {/* <figcaption>Sofa</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/2.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/3.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/4.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/5.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/6.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/7.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/8.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/9.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/10.png" alt="" />
                  {/* <figcaption>Sofa</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/11.png" alt="" />
                  {/* <figcaption>Sofa</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/12.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/13.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/14.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/15.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/16.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/17.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/18.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/19.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/20.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/21.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
                <figure>
                  <img src="/assets/images/22.png" alt="" />
                  {/* <figcaption>Accessories</figcaption> */}
                </figure>
               
             
            </div> {/*/owl-icons*/}
          </div> {/*/container*/}
        </section>
    
    
      <section className="instagram">
        {/* === instagram header === */}
        
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 text-center">
                <h2 className="h2 title">Follow us 
                  {/* <i className="fa fa-instagram fa-2x" /> Instagram  */}
                  </h2>
                <div className="text">
                  <p>@CitizenPlastic</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* === instagram gallery === */}
        {/* <div className="gallery clearfix">
          <a className="item" href="/">
            <img src="assets/images/square-1.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-2.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-3.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-4.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-5.jpg" ="Alternate Text" />
          </a>
          <a className="item" href="/">
            <img src="assets/images/square-6.jpg" ="Alternate Text" />
          </a>
        </div> /gallery */}
      </section>
      
    </>
  )
}

// <div class="panel panel-default" id="carousel-icons">
//                             <div class="panel-heading">Carousel icons</div>
//                             <div class="panel-body">

//                                 <div class="owl-icons-wrapper">

                                   
                                            

//                                         <div class="owl-controls clickable"><div class="owl-buttons"><div class="owl-prev"><span class="icon icon-chevron-left"></span></div><div class="owl-next"><span class="icon icon-chevron-right"></span></div></div></div></div>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>